import axios from "axios";
let serverUrl = "";
// serverUrl = "http://localhost:3000";
serverUrl = `${process.env.GATSBY_SERVER_API_URL}`;
export const createShopifyCustomer = (firstName, lastName, email, password) => {
  const query = `
    mutation {
      customerCreate(input: {
        firstName: "${firstName}",
        lastName: "${lastName}"
        email: "${email}",
        password: "${password}"
      }) {
        userErrors {
          field
          message
        }
        customer {
          id
        }
      }
    }`;
  return axios.post(
    `https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2023-01/graphql.json`,
    { query },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token":
          process.env.GATSBY_STOREFRONT_ACCESS_TOKEN
      }
    }
  );
};

export const authenticateShopifyCustomer = (email, password) => {
  const query = `
    mutation {
      customerAccessTokenCreate(input: {
        email: "${email}",
        password: "${password}"
      }) {
        userErrors {
          field
          message
        }
        customerAccessToken {
          accessToken
          expiresAt
        }
      }
    }`;
  return axios.post(
    `https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2023-01/graphql.json`,
    { query },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token":
          process.env.GATSBY_STOREFRONT_ACCESS_TOKEN
      }
    }
  );
};

export const getShopifyCustomer = (accessToken) => {
  const query = `
    query {
      customer(customerAccessToken: "${accessToken}") {
        defaultAddress {
          address1
          address2
          city
          countryCodeV2
          provinceCode
          firstName
          lastName
          phone
          zip
          id
        }
        addresses (first: 10) {
          edges {
            node {
              address1
              address2
              city
              countryCodeV2
              provinceCode
              firstName
              lastName
              phone
              zip
              id
            }
          }
        }
        acceptsMarketing
        orders(first: 25, reverse: true) {
          edges {
            cursor
              node {
                processedAt
                name
                financialStatus
                fulfillmentStatus
                id
                lineItems (first: 10) {
                  edges {
                    node {
                        title
                        quantity
                        variant {
                          title
                          id
                        }
                    }
                  }
                }
                totalPriceV2 {
                  amount
                }
              }
          }
        }
        id
        email
      }
    }`;
  return axios.post(
    `https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2023-01/graphql.json`,
    { query },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token":
          process.env.GATSBY_STOREFRONT_ACCESS_TOKEN
      }
    }
  );
};

export const getCustomerOrders = (id) => {
  return axios.get(`${serverUrl}/api/customer-orders?customer_id=${id}`);
};
export const getCustomerOrderById = (id) => {
  return axios.get(`${serverUrl}/api/customer-orders/order?order_id=${id}`);
};

export const getCustomerSubscriptions = (accessToken, shopify_id) => {
  return axios.get(
    `${serverUrl}/api/stripe/customer-subscriptions/${shopify_id}`
  );
};

export const cancelSubscription = (id) => {
  return axios.post(`${serverUrl}/api/stripe/customer-subscriptions/cancel`, {
    chargeId: id
  });
};

export const getCustomerSubById = (id) => {
  return axios.get(`${serverUrl}/api/stripe/subscription?charge_id=${id}`);
};
export const updateDeliveryDate = (date, charge_id) => {
  return axios.post(`${serverUrl}/api/stripe/update-next-charge-date`, {
    date,
    charge_id
  });
};
export const updateAddress = (addressValues, chargeId) => {
  return axios.post(`${serverUrl}/api/stripe/update-address`, {
    addressValues,
    chargeId
  });
};

export const subscribeToEmailList = (
  email,
  interestedInOnlineShop = false,
  interestedInRestaurantAndTrucks = false
) => {
  return axios.post(`${serverUrl}/api/marketing/subscribe`, {
    email,
    interestedInOnlineShop,
    interestedInRestaurantAndTrucks
  });
};

// export const saveAddress = async (accessToken, values) => {
//   const query = `
//     mutation customerAddressCreate($customerAccessToken: String!, $address: MailingAddressInput!) {
//       customerAddressCreate(customerAccessToken: $customerAccessToken, address: $address) {
//         customerAddress {
//           id
//         }
//         customerUserErrors {
//           code
//           field
//           message
//         }
//       }
//     }
// `;
//   const variables = {
//     customerAccessToken: accessToken,
//     address: values,
//   };
//   const addedAddress = await axios.post(
//     `https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2019-10/graphql.json`,
//     { query, variables },
//     {
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         "X-Shopify-Storefront-Access-Token":
//           process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
//       },
//     }
//   );
//   console.log(addedAddress);
//   const addressId =
//     addedAddress.data.data.customerAddressCreate.customerAddress.id;
//   console.log(addressId);
//   const query2 = `
//     mutation customerDefaultAddressUpdate {
//       customerDefaultAddressUpdate(customerAccessToken: "${accessToken}", addressId: "${addressId}") {
//         customer {
//           id
//         }
//         customerUserErrors {
//           code
//           field
//           message
//         }
//       }
//     }
// `;
//   const savedAddress = await axios.post(
//     `https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2019-10/graphql.json`,
//     { query: query2 },
//     {
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         "X-Shopify-Storefront-Access-Token":
//           process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
//       },
//     }
//   );
//   console.log(savedAddress);
//   return savedAddress;
// };

export const setDefaultAddress = async (accessToken, addressId) => {
  const query = `
    mutation customerDefaultAddressUpdate($customerAccessToken: String!, $addressId: ID!) {
      customerDefaultAddressUpdate(customerAccessToken: $customerAccessToken, addressId: $addressId) {
        customer {
          id
        }
        customerUserErrors {
          code
          field
          message
        }
      }
    }
  `;
  const variables = {
    customerAccessToken: accessToken,
    addressId: addressId
  };

  const customer = await axios.post(
    `https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2023-01/graphql.json`,
    { query, variables },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token":
          process.env.GATSBY_STOREFRONT_ACCESS_TOKEN
      }
    }
  );

  return customer;
};

export const createAddress = async (accessToken, values) => {
  const query = `
    mutation customerAddressCreate($customerAccessToken: String!, $address: MailingAddressInput!) {
    customerAddressCreate(customerAccessToken: $customerAccessToken, address: $address) {
      customerAddress {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
  `;
  const variables = {
    customerAccessToken: accessToken,
    address: values
  };

  const newAddress = await axios.post(
    `https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2023-01/graphql.json`,
    { query, variables },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token":
          process.env.GATSBY_STOREFRONT_ACCESS_TOKEN
      }
    }
  );

  return newAddress;
};

export const saveAddress = async (accessToken, values, id) => {
  const query = `
      mutation customerAddressUpdate($customerAccessToken: String!, $id: ID!, $address: MailingAddressInput!) {
      customerAddressUpdate(customerAccessToken: $customerAccessToken, id: $id, address: $address) {
        customerAddress {
          id
        }
        customerUserErrors {
          code
          field
          message
        }
      }
    }
  `;
  const variables = {
    customerAccessToken: accessToken,
    address: values,
    id
  };
  const savedAddress = await axios.post(
    `https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2023-01/graphql.json`,
    { query, variables },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token":
          process.env.GATSBY_STOREFRONT_ACCESS_TOKEN
      }
    }
  );
  return savedAddress;
};
export const deleteAddress = async (accessToken, id) => {
  const query = `
      mutation customerAddressDelete($id: ID!, $customerAccessToken: String!) {
      customerAddressDelete(id: $id, customerAccessToken: $customerAccessToken) {
        customerUserErrors {
          code
          field
          message
        }
        deletedCustomerAddressId
      }
    }
  `;
  const variables = {
    customerAccessToken: accessToken,
    id
  };
  const deletedAddress = await axios.post(
    `https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2023-01/graphql.json`,
    { query, variables },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token":
          process.env.GATSBY_STOREFRONT_ACCESS_TOKEN
      }
    }
  );
  return deletedAddress;
};

export const sendForgotPWEmail = (email) => {
  const query = `
      mutation {
        customerRecover(email: "${email}") {
          customerUserErrors {
            code
            field
            message
          }
        }
      }
    `;
  return axios.post(
    `https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2023-01/graphql.json`,
    { query },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token":
          process.env.GATSBY_STOREFRONT_ACCESS_TOKEN
      }
    }
  );
};

export const resetPassword = (id, resetToken, password) => {
  const query = `mutation customerReset($id: ID!, $input: CustomerResetInput!) {
      customerReset(id: $id, input: $input) {
        customer {
          id
        }
        customerAccessToken {
          accessToken
          expiresAt
        }
        customerUserErrors {
          code
          field
          message
        }
      }
    }`;
  const variables = {
    id,
    input: {
      resetToken,
      password
    }
  };
  return axios.post(
    `https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2023-01/graphql.json`,
    { query, variables },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token":
          process.env.GATSBY_STOREFRONT_ACCESS_TOKEN
      }
    }
  );
};

export const activateAccount = (activationUrl, password) => {
  const query = `
        mutation customerActivateByUrl($activationUrl: URL!, $password: String!) {
          customerActivateByUrl(activationUrl: $activationUrl, password: $password) {
            customer {
              id
            }
            customerAccessToken {
              accessToken
              expiresAt
            }
            customerUserErrors {
              code
              field
              message
            }
          }
        }
    `;
  const variables = {
    activationUrl,
    password
  };
  return axios.post(
    `https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2023-01/graphql.json`,
    { query, variables },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token":
          process.env.GATSBY_STOREFRONT_ACCESS_TOKEN
      }
    }
  );
};
