import axios from 'axios';

const appUrl = process.env.GATSBY_EMBEDDED_API_URL;

export const getAssociationsByVariantId = variantId => {
  return axios.get(`${appUrl}/api/association-by-variant-id?variantId=${variantId}`);
};

export const getProductVariantInventory = variantId => {
  console.log('variantId', variantId);
  const query = `
    query {
      node(id: "${variantId}") {
        ... on ProductVariant {
           id
           quantityAvailable
        }
      }
    }`;
  return axios.post(
    `https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2023-01/graphql.json`,
    { query },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
      },
    }
  );
};
