import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import StoreContext from '~/layouts/StoreContext';

const Bar = styled.span`
    position: fixed;
    top: 0;
    left: 0;
    height: 3px;
    width: ${({ w }) => `${w}%`};
    opacity: ${({ w }) => (w > 0 ? 1 : 0)};
    background: #d4212c;
    transition: width 0.25s ease-in-out;
    z-index: 9999;
    pointer-events: none;
`;
const LoadingBar = () => {
    const loadingBar = useRef();
    const {
        updateLoadingBarProgress,
        store: { loadingBarProgress },
    } = useContext(StoreContext);

    const handleTransitionEnd = () => {
        if (loadingBarProgress === 100) {
            updateLoadingBarProgress(0);
        }
    };

    return <Bar ref={loadingBar} w={loadingBarProgress} onTransitionEnd={handleTransitionEnd} />;
};

export default LoadingBar;
