import React, { useCallback, useRef, useState } from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';

import { WhiteButton } from '~/components/.base/buttons';
import { FlexBox, Row, Col } from '~/components/.base/containers';
import { Heading, BodyTextAlt } from '~/components/.base/headings';
import { MegaMenuWrapper } from '../MegaMenu';

import ArrowIcon from '~/images/icons/arrow_right_mega_menu.svg';
import CaretIcon from '~/images/icons/caret.svg';

const MenuItem = ({ link, visibleHeaderLinks, index }) => {
    const [submenuVisible, setSubmenuVisible] = useState(false);
    let mouseOutTimer; // timer used to delay hiding of menu after mouse leaves
    const mouseOutHideDelay = 100; // time (in ms) before menu is

    const toggleButtonRef = useRef(null);
    const menuItemWrapperRef = useRef(null);

    // Show the submenu on mouse hover of the trigger
    const handleMenuItemHover = () => {
        handleShowSubmenu();
        clearTimeout(mouseOutTimer);
    };

    // Hide the submenu when mouse hover leaves both the trigger and submenu fly-out, but only after a short delay to help people jerky mouse movements (like those using head/eye trackers)
    const handleMenuItemHoverOut = () => {
        mouseOutTimer = setTimeout(function () {
            handleHideSubmenu();
        }, mouseOutHideDelay);
    };

    /**
    Submenu visibility
  */
    const handleShowSubmenu = useCallback(() => {
        if (menuItemWrapperRef?.current)
            menuItemWrapperRef.current.setAttribute('aria-expanded', true);

        if (toggleButtonRef?.current) toggleButtonRef.current.setAttribute('aria-expanded', true);

        setSubmenuVisible(true);
    }, [toggleButtonRef]);

    const handleHideSubmenu = useCallback(() => {
        if (menuItemWrapperRef?.current)
            menuItemWrapperRef.current.setAttribute('aria-expanded', false);

        if (toggleButtonRef?.current) toggleButtonRef.current.setAttribute('aria-expanded', false);

        setSubmenuVisible(false);
    }, [toggleButtonRef]);

    const handleToggleSubmenu = () => {
        if (submenuVisible) {
            handleHideSubmenu();
        } else {
            handleShowSubmenu();
        }
    };

    /**
    Event handlers
  */
    const handleTriggerKeydown = useCallback(e => {
        // Hide the submenu a keyboard user tabs out of it or presses Escape
        if (
            (e.key === 'Tab' && !e.shiftKey && index === visibleHeaderLinks.length - 1) ||
            e.key === 'Escape'
        ) {
            handleHideSubmenu();

            // Move focus back to the menu toggle button if Escape was pressed
            if (e.key === 'Escape') {
                toggleButtonRef.current.focus();
            }
        }
    });

    return (
        <li>
            <div
                ref={menuItemWrapperRef}
                className={`ecomm-header-link ${
                    index === visibleHeaderLinks.length - 1 ? 'last' : ''
                } ${link.subLinks || (link.useMegaMenu && link.megaMenu) ? 'has-sublinks' : ''}`}
                onMouseOver={handleMenuItemHover}
                onMouseOut={handleMenuItemHoverOut}
                onKeyDown={handleTriggerKeydown}
                aria-expanded="false"
            >
                <div className="ecomm-header-link-inner">
                    <Link to={link.url}>
                        <span>{link.title}</span>
                    </Link>
                    {link.subLinks || (link.useMegaMenu && link.megaMenu) ? (
                        <button
                            ref={toggleButtonRef}
                            className="toggle-button"
                            aria-expanded="false"
                            aria-label="toggle menu"
                            aria-controls={`submenu-${index}`}
                            id={`menu-item-${index}`}
                            onClick={handleToggleSubmenu}
                        >
                            <CaretIcon />
                        </button>
                    ) : null}
                </div>
                {link.subLinks && !link.useMegaMenu ? (
                    <ul className="sublinks" id={`submenu-${index}`} aria-labelledby={`${link.title.replaceAll(' ', '-').toLowerCase()}-menu-${index}`} tabindex="0" role="region">
                        {link.subLinks.map((subLink, i) => (
                            <li key={i}>
                                <Link
                                    to={subLink.url}
                                    state={{
                                        sourceCollection: {
                                            title: link.title,
                                            handle: link.handle,
                                        },
                                    }}
                                >
                                    <span
                                        className={`sublink ${i === 0 ? 'first' : ''}${
                                            i === link.subLinks.length - 1 ? 'last' : ''
                                        }`}
                                    >
                                        <span>{subLink.title}</span>
                                    </span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                ) : null}
            </div>
            {link.useMegaMenu && link.megaMenu ? (
                <MegaMenuWrapper id={`submenu-${index}`} aria-labelledby={`menu-item-${index}`} tabindex="0" role="region">
                    <Row>
                        <Col as="ul" size={1} className="mega-menu-links-cont">
                            {link.megaMenu.subLinks.map((link, i) => {
                                if (!link.showLink) return null;
                                return (
                                    <li key={i}>
                                        <Link className="mega-menu-link" to={link.url}>
                                            <FlexBox as="span" justify="space-between">
                                                <BodyTextAlt
                                                    className="mega-menu-link-text"
                                                    as="span"
                                                >
                                                    {link.title}
                                                </BodyTextAlt>{' '}
                                                <ArrowIcon />
                                            </FlexBox>
                                        </Link>
                                    </li>
                                );
                            })}
                        </Col>
                        <Col size={2} className="tout-cont">
                            <FlexBox>
                                <Link className="tout big-tout" to={link.megaMenu.toutOneLink}>
                                    <FlexBox>
                                        <Image fluid={link.megaMenu.toutOneImage.fluid} alt="" />
                                        <FlexBox
                                            className="tout-info p-b"
                                            direction="column"
                                            justify="space-between"
                                        >
                                            <Heading>{link.megaMenu.toutOneText}</Heading>
                                            <FlexBox align="center">
                                                <BodyTextAlt md="0 1rem 0 0">Shop Now</BodyTextAlt>{' '}
                                                <WhiteButton as="span" pd="0" w="40px">
                                                    <ArrowIcon />
                                                </WhiteButton>
                                            </FlexBox>
                                        </FlexBox>
                                    </FlexBox>
                                </Link>
                                <ul className="little-touts">
                                    <li>
                                        <Link to={link.megaMenu.toutTwoLink}>
                                            <FlexBox className="tout little-tout">
                                                <Image
                                                    fluid={link.megaMenu.toutTwoImage.fluid}
                                                    alt=""
                                                />
                                                <FlexBox
                                                    className="tout-info p-b"
                                                    direction="column"
                                                    justify="space-between"
                                                >
                                                    <Heading>{link.megaMenu.toutTwoText}</Heading>
                                                    <FlexBox align="center">
                                                        <BodyTextAlt md="0 1rem 0 0">
                                                            Shop Now
                                                        </BodyTextAlt>{' '}
                                                        <WhiteButton as="span" pd="0" w="40px">
                                                            <ArrowIcon />
                                                        </WhiteButton>
                                                    </FlexBox>
                                                </FlexBox>
                                            </FlexBox>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={link.megaMenu.toutThreeLink}>
                                            <FlexBox className="tout little-tout">
                                                <Image
                                                    fluid={link.megaMenu.toutThreeImage.fluid}
                                                    alt=""
                                                />
                                                <FlexBox
                                                    className="tout-info p-t"
                                                    direction="column"
                                                    justify="space-between"
                                                >
                                                    <Heading>{link.megaMenu.toutThreeText}</Heading>
                                                    <FlexBox align="center">
                                                        <BodyTextAlt md="0 1rem 0 0">
                                                            Shop Now
                                                        </BodyTextAlt>{' '}
                                                        <WhiteButton as="span" pd="0" w="40px">
                                                            <ArrowIcon />
                                                        </WhiteButton>
                                                    </FlexBox>
                                                </FlexBox>
                                            </FlexBox>
                                        </Link>
                                    </li>
                                </ul>
                            </FlexBox>
                        </Col>
                    </Row>
                </MegaMenuWrapper>
            ) : null}
        </li>
    );
};
export default MenuItem;
