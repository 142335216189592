import React, { useCallback, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'gatsby';

import { size } from '~/constants/Breakpoint';
import './FooterEcommerce.scss';
import { Newsletter } from '~/components/Navigation';
import { BodyTextAlt, Subheading } from '~/components/.base/headings';
import { FlexBox } from '~/components/.base/containers';
import { WhiteButton } from '~/components/.base/buttons';
import EmailIcon from '~/images/icons/email.svg';
import PhoneIcon from '~/images/icons/phone.svg';
import { css } from 'styled-components';

const FooterEcommerce = ({ footerImages, footerData, cartHeader, policyLinks }) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${size.lg - 1}px)` });

  useEffect(() => {
    if (window && !isMobile) {
      var lastScrollTop = 0;
      const footer = document.querySelector('#sticky-footer');
      const anchor = document.getElementById('___gatsby');
      const footerScroll = () => {
        const st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
        if (st < 0) {
          footer.classList.remove('hidden');
        } else {
          if (st > lastScrollTop) {
            footer.classList.add('hidden');
          } else {
            footer.classList.remove('hidden');
          }
        }
        if (st > anchor.getBoundingClientRect().height - window.innerHeight - 400) {
          footer.classList.add('bottom');
        } else {
          if (footer.classList.contains('bottom')) {
            footer.classList.remove('bottom');
            footer.classList.add('hidden');
            window.setTimeout(() => {
              footer.classList.remove('hidden');
            }, 300);
          }
        }
        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
      };
      footerScroll();
      window.addEventListener('scroll', footerScroll, false);
      return function cleanup() {
        window.removeEventListener('scroll', footerScroll);
      };
    }
  }, []);

  const footerRef = useRef(null);
  const handleFooterHeight = useCallback(() => {
    if (footerRef?.current) {
      document.documentElement.style.setProperty(
        '--footer-height',
        footerRef.current.offsetHeight + 'px'
      );
    }
  }, [footerRef]);
  useEffect(() => {
    handleFooterHeight();
    // set resize listener
    window.addEventListener('resize', handleFooterHeight);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', handleFooterHeight);
    };
  }, [handleFooterHeight]);
  return (
    <footer>
      {!cartHeader ? <Newsletter footerImages={footerImages} /> : null}
      <div ref={footerRef} id="footer" className="ecomm-footer desktop bottom">
        <FlexBox
          justify="space-between"
          align="center"
          css={css`
            flex-wrap: wrap;
          `}
          className="main-footer-links"
        >
          <FlexBox as="ul" className="left-links" justify="space-between" align="center">
            <li>
              <a href={`mailto:${footerData.emailAddress}`}>
                <WhiteButton as="span" className="no-stroke" pd="0" w="40px" h="40px">
                  <EmailIcon />
                </WhiteButton>
                <BodyTextAlt>{footerData.emailAddress}</BodyTextAlt>
              </a>
            </li>
            <li>
              <a href={`tel:${footerData?.phoneNumber?.replaceAll(/\s/g, '')}`}>
                <WhiteButton as="span" pd="0" w="40px" h="40px" className="no-stroke">
                  <PhoneIcon />
                </WhiteButton>
                <BodyTextAlt>{footerData.phoneNumber}</BodyTextAlt>
              </a>
            </li>
          </FlexBox>
          <FlexBox
            as="ul"
            className="policy-link"
            css={css`
              ${Subheading} {
                margin-bottom: 0;
              }
            `}
          >
            {policyLinks.links.map((link, i) => (
              <li key={i}>
                <Link to={link.url}>
                  <Subheading as="span" className="text--subdued">
                    {link.title}
                  </Subheading>
                </Link>
              </li>
            ))}
          </FlexBox>
          <div className="copyright">
            <BodyTextAlt md="0">© COPYRIGHT 2021, all rights reserved</BodyTextAlt>
          </div>
        </FlexBox>
        <div className="requirement-links d-flex">
          <div className="copyright">
            <BodyTextAlt md="0">© COPYRIGHT 2021, all rights reserved</BodyTextAlt>
          </div>
          <ul className="d-flex">
            {footerData.caRequirementLinks.map(({ url, title, cssClass }, i) => {
              if (title?.toLowerCase().includes('consent')) {
                return (
                  <li key={i}>
                    <a
                      id="termly-consent-preferences"
                      onClick={() => {
                        window?.displayPreferenceModal();
                        return false;
                      }}
                    >
                      <Subheading as="span" className="ecommFooterTop__link text--subdued">
                        {title}
                      </Subheading>
                    </a>
                  </li>
                )
              }

              return (
                <li key={i} className="ecommFooterTop__linkItem">
                  <a
                    href={url}
                    target='_blank'
                  >
                    <Subheading as="span" className="ecommFooterTop__link text--subdued">
                      {title}
                    </Subheading>
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <div id="sticky-footer" className="ecomm-footer desktop sticky">
        <FlexBox
          justify="space-between"
          align="center"
          css={css`
            flex-wrap: wrap;
          `}
        >
          <FlexBox as="ul" className="left-links" justify="space-between" align="center">
            <li>
              <a href={`mailto:${footerData.emailAddress}`}>
                <WhiteButton as="span" className="no-stroke" pd="0" w="40px" h="40px">
                  <EmailIcon />
                </WhiteButton>
                <BodyTextAlt>{footerData.emailAddress}</BodyTextAlt>
              </a>
            </li>
            <li>
              <a href={`tel:${footerData?.phoneNumber?.replaceAll(/\s/g, '')}`}>
                <WhiteButton as="span" pd="0" w="40px" h="40px" className="no-stroke">
                  <PhoneIcon />
                </WhiteButton>
                <BodyTextAlt>{footerData.phoneNumber}</BodyTextAlt>
              </a>
            </li>
          </FlexBox>
          <FlexBox as="ul" className="policy-link">
            {policyLinks.links.map((link, i) => (
              <li key={i}>
                <Link to={link.url}>
                  <Subheading as="span" className="text--subdued">
                    {link.title}
                  </Subheading>
                </Link>
              </li>
            ))}
          </FlexBox>
          <div className="copyright">
            <BodyTextAlt md="0">© COPYRIGHT 2021, all rights reserved</BodyTextAlt>
          </div>
        </FlexBox>
      </div>
      <div id="footer-mobile" className={`ecomm-footer mobile ${cartHeader ? 'cart-footer' : ''}`}>
        <FlexBox className="ecommFooterTop">
          <FlexBox as="ul" className="ecommFooterTop__contact btn-group btnGroup" align="center">
            <li className="btnGroup__item">
              <a className="btnGroup__btn" href={`mailto:${footerData.emailAddress}`}>
                <WhiteButton as="span" className="no-stroke" pd="0" w="40px" h="40px">
                  <EmailIcon />
                </WhiteButton>
              </a>
            </li>
            <li className="btnGroup__item">
              <a
                className="btnGroup__btn"
                href={`tel:${footerData?.phoneNumber?.replaceAll(/\s/g, '')}`}
              >
                <WhiteButton as="span" pd="0" w="40px" h="40px" className="no-stroke">
                  <PhoneIcon />
                </WhiteButton>
              </a>
            </li>
          </FlexBox>
          <FlexBox direction="column" as="ul" className="ecommFooterTop__linkList">
            {policyLinks?.links?.length > 0 &&
              policyLinks.links.map((link, i) => (
                <li key={i} className="ecommFooterTop__linkItem">
                  <Link to={link.url}>
                    <Subheading as="span" className="ecommFooterTop__link text--subdued">
                      {link.title}
                    </Subheading>
                  </Link>
                </li>
              ))}
          </FlexBox>
        </FlexBox>
        <div className="copyright">
          <BodyTextAlt md="0">© COPYRIGHT 2021, all rights reserved</BodyTextAlt>
        </div>
        <div className="requirement-links">
          <ul className="d-flex">
            {footerData.caRequirementLinks.map(({ url, title, cssClass }, i) => {
              if (title?.toLowerCase().includes('consent')) {
                return (
                  <li key={i} className="ecommFooterTop__linkItem">
                    <a
                      id="termly-consent-preferences"
                      onClick={() => {
                        window?.displayPreferenceModal();
                        return false;
                      }}
                    >
                      <Subheading as="span" className="ecommFooterTop__link text--subdued">
                        {title}
                      </Subheading>
                    </a>
                  </li>
                )
              }

              return (
                <li key={i} className="ecommFooterTop__linkItem">
                  <a
                    href={url}
                    target='_blank'
                  >
                    <Subheading as="span" className="ecommFooterTop__link text--subdued">
                      {title}
                    </Subheading>
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default FooterEcommerce;
