import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import Cookies from 'js-cookie';
import { css } from 'styled-components';
import { gsap } from 'gsap';

import StoreContext from '~/layouts/StoreContext';
import { breakpoint } from '~/constants/Breakpoint';
import { Default, Xxl, MaxXl, Mobile } from 'components/.base/responsive';
import { WhiteButton } from '~/components/.base/buttons';
import { FlexBox } from '~/components/.base/containers';
import { BodyText, BodyTextAlt } from '~/components/.base/headings';
import { SidebarCart, SidebarMenuEcomm } from '~/components/Navigation';

import AccountIcon from '~/images/icons/account.svg';
import CartIcon from '~/images/icons/cart.svg';
import CloseIcon from '~/images/icons/close.svg';
import MobileBurgerIcon from '~/images/icons/mobile_burger.inline.svg';
import HomePageIcon from '~/images/icons/homepage.svg';

import { StackedLogo, LinearLogo } from '../Logos';
import MenuItem from './MenuItem';
import { ComponentWrapper } from './EcommerceMenu.styled';

const EcommerceMenu = ({ isShopPage, headerLinks, policyLinks, isPDP }) => {
  const [windowLoaded, setWindowLoaded] = useState(false);
  const [domLoaded, setDomLoaded] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const {
    openCart,
    openMenu,
    closeMenu,
    store: {
      sidebarMenuOpen,
      whiteHeader,
      client,
      checkout,
      checkout: { lineItems },
      shippingActive,
    },
    applyDiscount,
    setHeaderWhite,
    setShippingActive,
  } = useContext(StoreContext);

  const [isTop, setIsTop] = useState(true);
  const customer = Cookies.get('KEPT_SESS');

  const visibleHeaderLinks = headerLinks.links.filter(link => link.showLink);

  const handleHeaderProperty = () => {
    if (document.body.contains(document.querySelector('#navigation.top'))) {
      document.documentElement.style.setProperty(
        '--header-height',
        document.querySelector('#navigation.top').offsetHeight + 'px'
      );
    }
    if (document.body.contains(document.querySelector('#announcementBar'))) {
      document.documentElement.style.setProperty(
        '--announcement-bar-height',
        document.querySelector('#announcementBar').offsetHeight + 'px'
      );
    } else {
      document.documentElement.style.setProperty('--announcement-bar-height', '0px');
    }
  };
  const handleShippingActive = () => {
    gsap.timeline().to(`.shipping-bar`, {
      duration: 0.3,
      yPercent: '-100',
      onComplete: setShippingActive,
      onCompleteParams: [false],
    });
  };

  useEffect(() => {
    if (window) {
      // timeoutId for debounce mechanism
      const getWidth = () =>
        window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      let timeoutId = null;
      setWindowWidth(getWidth());
      const resizeListener = () => {
        // prevent execution of previous setTimeout
        clearTimeout(timeoutId);
        // change width from the state object after 150 milliseconds
        timeoutId = setTimeout(() => setWindowWidth(getWidth()), 150);
      };
      // set resize listener
      window.addEventListener('resize', resizeListener);

      document.addEventListener('DOMContentLoaded', function (event) {
        setDomLoaded(true);
      });
      window.addEventListener('load', event => {
        // Page is fully loaded
        setWindowLoaded(true);
      });
      return () => {
        setWindowLoaded(false);
        setDomLoaded(false);
        setWindowWidth(false);
        window.removeEventListener('resize', resizeListener);
        window.removeEventListener('DOMContentLoaded', resizeListener);
        window.removeEventListener('load', resizeListener);
      };
    }
  }, []);
  useEffect(() => {
    if (window) {
      handleHeaderProperty();
      /* setHeaderWhite(
        window.location.pathname === "/" ||
          window.location.pathname === "/shop" ||
          window.location.pathname.includes("/collections")
      ); */
      let nav = document.querySelector('#navigation');

      let lastScrollTop = 0;
      const headerScroll = function () {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
        if (scrollTop < 100) {
          if (scrollTop < lastScrollTop) {
            setIsTop(true);
            nav.classList.add('top');
            nav.classList.remove('shrinked');
            nav.classList.remove('sticky');
          }
        } else if (scrollTop < 120) {
          setIsTop(false);
          nav.classList.remove('top');
          nav.classList.remove('sticky');
        } else if (scrollTop < 200) {
          setIsTop(false);
          nav.classList.remove('top');
          nav.classList.remove('shrinked');
          nav.classList.remove('sticky');
        } else if (scrollTop < 500) {
          setIsTop(false);
          nav.classList.remove('top');
          nav.classList.add('shrinked');
          nav.classList.remove('sticky');
        } else {
          setIsTop(false);
          nav.classList.remove('top');
          nav.classList.add('shrinked');
          if (scrollTop > lastScrollTop) {
            nav.classList.remove('sticky');
          } else {
            nav.classList.add('sticky');
          }
        }
        lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
      };

      const reqFrame = () => {
        requestAnimationFrame(headerScroll);
      };
      window.addEventListener('scroll', reqFrame, false);
      return function cleanup() {
        window.removeEventListener('scroll', reqFrame);
      };
    }
  }, [windowLoaded, domLoaded, windowWidth, shippingActive]);

  useEffect(() => {
    // ZENDESK WIDGET POSITIONING
    if (window) {
      window.zESettings = {
        webWidget: {
          position: { horizontal: 'right', vertical: 'bottom' },
          offset: { vertical: '50px' },
        },
      };
    }
    if (window.zE) {
      window.zE('webWidget', 'show');
    }
    return () => {
      // hide widget if navigate to nonEcommerce part of site
      if (window.zE) {
        window.zE('webWidget', 'hide');
      }
    };
  });
  useEffect(() => {
    handleHeaderProperty();
  }, [shippingActive]);
  const [stopApplyingDiscount, setStopApplyingDiscount] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const params = new URLSearchParams(window.location.search);

    if (
      params.get('discount_code') &&
      checkout?.id &&
      client?.checkout?.addDiscount &&
      checkout?.discountApplications &&
      !stopApplyingDiscount
    ) {
      console.log('adding discount');

      setStopApplyingDiscount(true);
      applyDiscount(client, checkout.id, params.get('discount_code'))
        .then(res => {
          if (!res) {
          }
        })
        .catch(err => console.log(err));
    }
  }, [client, checkout]);

  return (
    <>
      <ComponentWrapper
        as="header"
        id="navigation"
        className={`navigation ecomm-navigation ${isTop ? ' top' : ''}${!isPDP ? ' hasHero' : ''}${
          whiteHeader && !sidebarMenuOpen ? ' white-header' : ''
        }${sidebarMenuOpen ? ' menu-open' : ''}${shippingActive ? ' shipping-active' : ''}`}
      >
        {shippingActive && (
          <FlexBox
            id="announcementBar"
            justify="center"
            className="shipping-bar"
            role="region"
            aria-label="announcement banner"
          >
            <BodyTextAlt as="h2" className="header" md="0">
              Shipping Info
            </BodyTextAlt>
            <Mobile>
              <BodyText md="0">
                $35 for orders &lt; $150 <span className="shipping-seperator">|</span> $19.99 for
                orders $150 - $250 <span className="shipping-seperator">|</span> Free for orders
                &gt; $250
              </BodyText>
            </Mobile>
            <Default>
              <ul>
                <li>
                  <BodyText>$35 flat rate shipping for orders under $150</BodyText>
                </li>
                <li>
                  <BodyText>$19.99 flat rate shipping for orders $150 - $250</BodyText>
                </li>
                <li>
                  <BodyText>Free shipping on orders over $250</BodyText>
                </li>
              </ul>
            </Default>
            <button
              className="shipping-close"
              aria-label="Close"
              onClick={() => handleShippingActive(false)}
            >
              &times;
            </button>
          </FlexBox>
        )}

        <FlexBox className="ecomm-navigation__top" align="center">
          <Xxl>
            <FlexBox className="flex-grow">
              <WhiteButton
                className="altFocus"
                as={Link}
                to="/"
                pd="0"
                w="40px"
                h="40px"
                aria-label="Go to homepage"
              >
                <HomePageIcon />
              </WhiteButton>
              <div
                css={css`
                  display: none;
                  .ecomm-navigation.shrinked & {
                    @media ${breakpoint.maxXxl} {
                      display: flex;
                      align-items: center;
                    }
                  }
                `}
              >
                <Link to="/shop" aria-label="Go to shop page">
                  <StackedLogo w="80px" h="37px" isWhite={false} />
                </Link>
              </div>
              <div
                css={css`
                  margin-left: 1rem;
                  display: none;
                  .ecomm-navigation.shrinked & {
                    @media ${breakpoint.xxxl} {
                      display: block;
                    }
                  }
                `}
              >
                <Link to="/shop" aria-label="Go to shop page">
                  <LinearLogo w="190px" isWhite={false} />
                </Link>
              </div>
            </FlexBox>
          </Xxl>
          <div className="left-side">
            <MaxXl>
              {sidebarMenuOpen ? (
                <button className="img-container burger" onClick={closeMenu}>
                  <CloseIcon />
                </button>
              ) : (
                <button
                  className="img-container burger"
                  onClick={() => {
                    if (shippingActive) {
                      handleShippingActive(false);
                    }
                    openMenu();
                  }}
                  aria-label="Open menu"
                >
                  <MobileBurgerIcon />
                </button>
              )}
            </MaxXl>
            <div
              css={css`
                .ecomm-navigation.shrinked & {
                  @media ${breakpoint.xxl} {
                    display: none;
                  }
                }
              `}
            >
              <Link to="/shop" aria-label="Go to shop page" onClick={closeMenu}>
                <span className="img-container logo">
                  {whiteHeader && isTop && !sidebarMenuOpen ? (
                    <StackedLogo isWhite={true} />
                  ) : (
                    <StackedLogo isWhite={false} />
                  )}
                </span>
              </Link>
            </div>
          </div>
          <FlexBox
            as="ul"
            className="ecommerceMenu__accountLinks right-side flex-grow flex-grow--right"
            align="center"
            css={css`
              justify-content: space-between;
              @media ${breakpoint.xl} {
                justify-content: flex-end;
              }
            `}
          >
            <li>
              <Link to={`/account/${customer ? 'dashboard/' : 'login/'}`} onClick={closeMenu}>
                <Default>
                  <BodyTextAlt as="span">{customer ? 'Account' : 'Login'}</BodyTextAlt>
                </Default>
                <AccountIcon />
              </Link>
            </li>
            <li>
              <WhiteButton
                aria-label={`Open sidebar cart (${
                  lineItems.length > 0 ? lineItems.length : 'No'
                } item${lineItems.length > 1 ? 's' : ''} in the cart)`}
                onClick={() => {
                  closeMenu();
                  openCart();
                }}
                pd="0"
                w="40px"
                h="40px"
                className="altFocus cart no-stroke"
              >
                <CartIcon />
                {lineItems.length ? <span className="cart-count">{lineItems.length}</span> : null}
              </WhiteButton>
            </li>
          </FlexBox>
        </FlexBox>
        <Xxl>
          <nav>
            <FlexBox as="ul" justify="center" className="sticky-links">
              {visibleHeaderLinks.map((link, index) => {
                return (
                  <MenuItem
                    key={index}
                    index={index}
                    link={link}
                    visibleHeaderLinks={visibleHeaderLinks}
                  />
                );
              })}
            </FlexBox>
          </nav>
        </Xxl>
      </ComponentWrapper>
      <SidebarCart />
      <SidebarMenuEcomm visibleHeaderLinks={visibleHeaderLinks} policyLinks={policyLinks} />
    </>
  );
};

export default EcommerceMenu;
