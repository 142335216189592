import styled from 'styled-components';

export const RedButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: ${props => props.w};
    margin: ${props => props.md};
    border-radius: 30px;
    height: ${props => (props.h ? props.h : '40px')};
    min-height: ${props => (props.h ? props.h : '40px')};
    padding: 0 28px;
    padding: ${props => props.pd};
    background-color: #d4212c;
    color: #fff;
    outline: none !important;
    border: 1px solid #d4212c;
    text-transform: uppercase;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
    transition: 0.2s linear;
    &:focus {
        &:not(:disabled) {
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4), 0 0 0 3px rgba(212, 33, 44, 0.75);
        }
    }
    @media screen and (min-width: 768px) {
        &:hover {
            &:not(:disabled) {
                background-color: #fff;
                color: #d4212c;
                svg {
                    stroke: #d4212c;
                    fill: #d4212c;
                    stop {
                        stop-color: #d4212c;
                    }
                    path {
                        stroke: #d4212c;
                    }
                }
            }
        }
    }
    &:disabled {
        opacity: 0.3;
    }
    &.no-stroke {
        svg {
            stroke: none;
        }
    }
    svg {
        margin-right: 0.75rem;
        stroke: #fff;
        fill: #fff;
        stop {
            stop-color: #fff;
        }
        path {
            stroke: #fff;
        }
    }
`;

export const LargeRedButton = styled(RedButton)`
    width: ${props => props.w};
    margin: ${props => props.md};
    height: 60px;
    font-size: 12px;
    padding: 0 60px;
`;

export const WhiteButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: ${props => props.w};
    height: ${props => (props.h ? props.h : '40px')};
    min-height: ${props => (props.h ? props.h : '40px')};
    margin: ${props => props.md};
    border-radius: 30px;
    padding: 0 28px;
    padding: ${props => props.pd};
    background-color: #fff;
    color: #d4212c;
    outline: none !important;
    border: 1px solid #e2e2e4;
    transition: all 0.2s linear;
    text-transform: uppercase;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
    transition: 0.2s linear;
    &:focus {
        &:not(:disabled) {
            box-shadow: 0 0 0 1px rgba(212, 33, 44, 0.75), 0 0 0 3px rgba(255, 255, 255, 0.75);
        }
    }
    @media screen and (min-width: 768px) {
        &:focus,
        &:hover {
            &:not(:disabled) {
                background-color: #d4212c;
                color: #fff;
                border-color: #d4212c;
                svg {
                    path {
                        fill: #fff;
                    }
                }
            }
        }
    }
    &.no-stroke {
        svg {
            stroke: none;
        }
    }
    svg {
        path {
            fill: #d4212c;
        }
    }
    @media screen and (max-width: 767px) {
        font-size: 12px;
    }
`;

export const WhiteSubscriptionButton = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: ${props => props.w};
    height: 40px;
    height: ${props => props.h};
    margin: ${props => props.md};
    border-radius: 30px;
    padding: 0 28px;
    padding: ${props => props.pd};
    background-color: #fff;
    color: #d4212c;
    outline: none !important;
    border: 1px solid #e2e2e4;
    transition: all 0.2s linear;
    text-transform: uppercase;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
    transition: 0.2s linear;
    g {
        transition: 0.2s linear;
        fill: #d4212c;
    }
    &:focus {
        &:not(:disabled) {
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4), 0 0 0 3px rgba(212, 33, 44, 0.75);
        }
    }
    @media screen and (min-width: 768px) {
        &:hover {
            &:not(:disabled) {
                background-color: #d4212c;
                color: #fff;
                border-color: #d4212c;
                svg {
                    g {
                        fill: #fff;
                    }
                }
            }
        }
    }
    &.no-stroke {
        svg {
            stroke: none;
        }
    }

    @media screen and (max-width: 767px) {
        font-size: 12px;
    }
`;

export const WhiteSubscriptionCircleButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: ${props => props.w};
    height: 40px;
    height: ${props => props.h};
    margin: ${props => props.md};
    border-radius: 30px;
    padding: 0 28px;
    padding: ${props => props.pd};
    background-color: #fff;
    color: #d4212c;
    outline: none !important;
    border: 1px solid #e2e2e4;
    transition: all 0.2s linear;
    text-transform: uppercase;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
    transition: 0.2s linear;
    g {
        transition: 0.2s linear;
        fill: #d4212c;
    }
    &:focus {
        &:not(:disabled) {
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4), 0 0 0 3px rgba(212, 33, 44, 0.75);
        }
    }
    @media screen and (min-width: 768px) {
        &:hover {
            &:not(:disabled) {
                background-color: #d4212c;
                color: #fff;
                border-color: #d4212c;
                svg {
                    g {
                        fill: #fff;
                    }
                }
            }
        }
    }
    &.no-stroke {
        svg {
            stroke: none;
        }
    }

    @media screen and (max-width: 767px) {
        font-size: 12px;
    }
`;

export const ButtonGroup = styled.div`
    display: flex;
    justify-content: center;
    height: 50px;
    border-radius: 25px;
    button,
    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
        width: ${props => props.buttonWidth};
        background-color: #d4212c;
        color: #fff;
        outline: none !important;
        border: 1px solid #d4212c;
        border-right: 1px solid rgba(255, 255, 255, 0.2);
        transition: all 0.2s linear;
        text-transform: uppercase;
        font-family: Montserrat, sans-serif;
        font-weight: 500;
        font-size: 12px;
        margin: 1rem 0;
        letter-spacing: 1px;
        &:focus {
            &:not(:disabled) {
                box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4), 0 0 0 3px rgba(212, 33, 44, 0.75);
            }
        }
        @media screen and (min-width: 768px) {
            &:hover {
                color: #d4212c;
                background-color: #fff;
                border: 1px solid #fff !important;
            }
        }
        &.first {
            border-top-left-radius: 25px;
            border-bottom-left-radius: 25px;
        }
        &.last {
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;
            border-right: 1px solid #d4212c;
        }
        &.is-selected {
            color: #d4212c;
            background-color: #fff;
            border: 1px solid #d4212c !important;
        }
    }
    @media screen and (max-width: 767px) {
        flex-direction: column;
        height: auto;
        align-items: center;
        margin: 3rem 0;
        button,
        .button {
            margin: 0;
            height: 40px;
            border: 1px solid #d4212c;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            &.first {
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
            }
            &.last {
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                border-bottom: 1px solid #d4212c;
            }
        }
    }
`;
