import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import Image from 'gatsby-image';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import StoreContext from '~/layouts/StoreContext';
import MobileBurgerIcon from '~/images/icons/mobile_burger.inline.svg';
import CloseIcon from '~/images/icons/close.svg';
import { SidebarMenu } from '~/components/Navigation';
import { RedButton, WhiteButton } from '~/components/.base/buttons';
import { StackedLogo, LinearLogo } from '../Logos';
import { breakpoint } from '../../../constants/Breakpoint';
import { Default, Mobile } from '../../.base/responsive';
import { MegaMenuWrapper } from '../MegaMenu';

import { FlexBox, Row, Col } from '~/components/.base/containers';
import { Heading, BodyTextAlt } from '~/components/.base/headings';
import CaretIcon from '~/images/icons/caret.svg';
import ArrowIcon from '~/images/icons/arrow_right_mega_menu.svg';
import AnnouncementBar from '../AnnouncementBar/AnnouncementBar';

const MainMenuWrapper = styled.nav`
  position: fixed;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  z-index: 20;
  top: 0px;
  .navigation-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .linear-logo {
    display: none;
  }
  .burger {
    border: none;
    background: none;
  }
  .img-container.burger {
    display: none;
  }
  @media ${breakpoint.maxMd} {
    .img-container {
      &.logo {
        margin-left: 0 !important;
      }
      &.burger {
        display: block;
        padding: 20px;
        outline: none;
        svg {
          width: 24px;
        }
      }
    }
  }
  @media ${breakpoint.maxLg} {
    display: flex;
    align-items: center;
    background: transparent;
    color: #5c6566;
    .header-link {
      text-transform: uppercase;
      font-family: Montserrat, sans-serif;
      padding: 1rem 0;
      font-weight: 500;
      letter-spacing: 1px;
      font-size: 12px;
      position: relative;
      svg {
        position: relative;
        left: -0.5rem;
        top: -0.05rem;
        g {
          transition: all 0.2s linear;
          fill: #5c6566;
        }
      }
      span {
        padding: 0 1rem;
      }
      &.last {
        padding-right: 1rem;
      }
      transition: all 0.2s linear;
      &:hover {
        color: #d4212c;
        > a {
          color: #d4212c;
        }
        svg g {
          fill: #d4212c;
        }
        .sublinks,
        ~ ${MegaMenuWrapper} {
          display: block;
        }
      }
    }
    &.white-header {
      color: #fff;
      svg path {
        fill: #fff;
      }
    }
    &:not(.top),
    &.menuOpen {
      background: #fff;
      color: #d9272e;
      box-shadow: 0 0 60px 0 rgba(46, 50, 51, 0.3);
      .burger path,
      .logo .prefix__st0 {
        fill: #cb2327;
      }
      .logo .prefix__st1 {
        fill: #231f20;
      }
    }
    .left-links {
      display: flex;
      align-items: center;
    }
    ${RedButton} {
      margin-right: 1rem;
    }
    .img-container {
      &.logo {
        width: 93px;
        margin-left: 16px;
      }
    }
    .pick-up-link {
      span {
        font-family: TimesNewRomanPSMT, serif;
        border-left: 1px solid #c4cbcc;
        padding: 0 1.5rem 0 2rem;
      }
    }
  }
  @media ${breakpoint.xl} {
    color: #5c6566;
    font-family: Montserrat, sans-serif;
    transition: all 0.2s ease-in-out;
    transform: translateY(-100%);
    /* background: transparent; */
    .navigation-wrapper {
      padding: 0 40px;
    }
    .d-flex {
      align-items: center;
    }
    .header-link > a {
      color: #5c6566;
    }
    .header-link {
      text-transform: uppercase;
      padding: 1rem 0;
      font-weight: 500;
      letter-spacing: 1px;
      font-size: 12px;
      position: relative;
      svg {
        position: relative;
        left: -0.5rem;
        top: -0.05rem;
        g {
          transition: all 0.2s linear;
          fill: #5c6566;
        }
      }
      span {
        padding: 0 1rem;
      }
      &.last {
        padding-right: 1rem;
      }
      transition: all 0.2s linear;
      &:hover {
        color: #d4212c;
        > a {
          color: #d4212c;
        }
        svg g {
          fill: #d4212c;
        }
        .sublinks,
        ~ ${MegaMenuWrapper} {
          display: block;
        }
      }
      .sublinks {
        display: none;
        position: absolute;
        top: 100%;
        border-radius: 30px;
        border: 1px solid #e2e2e4;
        background-color: #ffffff;
        width: fit-content;
        min-width: 225px;
        left: calc((100% - 225px) / 2);
        -webkit-box-shadow: 1px 9px 15px 3px rgba(0, 0, 0, 0.25);
        box-shadow: 1px 9px 15px 3px rgba(0, 0, 0, 0.25);
        .sublink {
          color: #5c6566;
          padding: 15px 20px;
          border-bottom: 1px solid #e2e2e4;
          transition: 0.2s all linear;
          text-align: center;
          font-size: 12px;
          &:hover {
            color: #fff;
            background-color: #d4212c;
          }
          span {
            padding: 0px;
          }
          &.first {
            padding-top: 25px;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
          }
          &.last {
            padding-bottom: 25px;
            border-bottom: none;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
          }
        }
      }
    }
    .right-links {
      justify-content: flex-end;
      align-items: center;
    }
    .logo {
      width: 140px;
    }
    .pick-up-link {
      padding: 1rem 0;
      font-size: 16px;
      margin-bottom: 2px;
      span {
        font-family: TimesNewRomanPSMT, serif;
        border-left: 1px solid #c4cbcc;
        padding: 0 1.5rem 0 2rem;
      }
    }
    &.top {
      transform: translateY(0);
      .navigation-wrapper {
        padding: 1rem 40px;
      }
      &.white-header {
        background: transparent;
        color: #fff;
        .header-link {
          > a {
            color: #fff;
          }
          &:hover {
            color: #d4212c;
            > a {
              color: #d4212c;
            }
          }
        }
        svg path {
          fill: #fff;
        }
      }
    }
    &.sticky {
      box-shadow: 0 0 60px 0 rgba(46, 50, 51, 0.2);
      position: fixed;
      height: 60px;
      display: flex;
      transform: translateY(0);
      background: #fff;
      .navigation-wrapper {
        padding: 0 40px;
      }
      .left-links {
        a {
          display: flex;
          align-items: center;
        }
        .linear-logo {
          display: block;
        }
        .stacked-logo,
        .stacked-logo-white {
          display: none;
        }
        .logo {
          width: 190px;
        }
      }
    }
  }
  @media ${breakpoint.onlyLg} {
    .header-link {
      font-size: 11px;
      letter-spacing: 0.5px;
      span {
        padding: 0 0.5rem;
      }
    }
    .pick-up-link {
      font-size: 14px;
      span {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
`;

const MainMenu = ({
  isHomePage,
  headerLinks,
  policyLinks,
  siteTitle,
  checkoutHeader,
  isSearchPage,
}) => {
  const [windowLoaded, setWindowLoaded] = useState(false);
  const [domLoaded, setDomLoaded] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  gsap.registerPlugin(ScrollTrigger);
  const {
    openMenu,
    closeMenu,
    store: { sidebarMenuOpen, whiteHeader },
    setHeaderWhite,
  } = useContext(StoreContext);
  const [isTop, setIsTop] = useState(true);

  const handleHeaderProperty = () => {
    if (document.body.contains(document.querySelector('#navigation.top'))) {
      document.documentElement.style.setProperty(
        '--header-height',
        document.querySelector('#navigation.top').offsetHeight + 'px'
      );
    }
    if (document.body.contains(document.querySelector('#announcementBar'))) {
      document.documentElement.style.setProperty(
        '--announcement-bar-height',
        document.querySelector('#announcementBar').offsetHeight + 'px'
      );
    } else {
      document.documentElement.style.setProperty('--announcement-bar-height', '0px');
    }
  };

  useEffect(() => {
    // timeoutId for debounce mechanism
    const getWidth = () =>
      window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => setWindowWidth(getWidth()), 150);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    document.addEventListener('DOMContentLoaded', function (event) {
      setDomLoaded(true);
    });
    window.addEventListener('load', event => {
      // Page is fully loaded
      setWindowLoaded(true);
    });
    return () => {
      setWindowLoaded(false);
      setDomLoaded(false);
      setWindowWidth(false);
      window.removeEventListener('resize', resizeListener);
      window.removeEventListener('DOMContentLoaded', resizeListener);
      window.removeEventListener('load', resizeListener);
    };
  }, []);
  useEffect(() => {
    let scrollTriggers = [];
    if (window) {
      handleHeaderProperty();
      let nav = document.querySelector('#navigation');
      let docHeight = document.querySelector('body').offsetHeight;

      let st = ScrollTrigger.create({
        trigger: 'window',
        start: 'top top',
        end: `+=${docHeight}`,
        scrub: 0,
        onUpdate: ({ progress, direction }) => {
          let scrollTop = progress * docHeight;
          if (scrollTop < 135) {
            setIsTop(true);
            nav.classList.add('top');
            nav.classList.remove('sticky');
          } else {
            setIsTop(false);
            nav.classList.remove('top');
            if (direction === 1) {
              nav.classList.remove('sticky');
            } else {
              nav.classList.add('sticky');
            }
          }
        },
      });
      scrollTriggers.push(st);
    }
    // Specify how to clean up after this effect:
    return () => {
      // all garbage cleanup all goes in a single useEffect for when component leaves
      if (scrollTriggers) {
        scrollTriggers.map(st => {
          st.kill();
          return true;
        });
      }
    };
  }, [windowLoaded, domLoaded, windowWidth]);

  return (
    <MainMenuWrapper
      as="header"
      id="navigation"
      className={`navigation main-navigation d-flex${sidebarMenuOpen ? ' menuOpen' : ''}${
        isTop ? ' top' : ''
      }${isHomePage ? ' home-page' : ''}${whiteHeader ? ' white-header' : ''}`}
    >
      {isTop && headerLinks.announcementBannerIsVisible && (
        <AnnouncementBar
          linkUrl={headerLinks.announcementBannerLink}
          bgColor={headerLinks.announcementBannerBgColor}
          content={headerLinks.announcementBannerContent}
        />
      )}
      <div className="navigation-wrapper">
        <FlexBox className="left-links">
          {sidebarMenuOpen ? (
            <button className="img-container burger" aria-label="close" onClick={closeMenu}>
              <CloseIcon />
            </button>
          ) : (
            <button className="img-container burger" onClick={openMenu} aria-label="Open menu">
              <MobileBurgerIcon />
            </button>
          )}
          <Link to="/" onClick={closeMenu} aria-label="Go to home">
            <div className="img-container logo">
              {whiteHeader ? <StackedLogo isWhite={true} /> : <StackedLogo isWhite={false} />}
              <LinearLogo w="190px" />
            </div>
          </Link>
        </FlexBox>
        <nav className="right-links">
          <FlexBox as="ul" align="center">
            <Default>
              {headerLinks.links.map((link, i) => {
                if (!link.showLink) return null;
                return (
                  <li key={i}>
                    <div
                      className={`header-link${i === headerLinks.links.length - 1 ? ' last' : ''} ${
                        link.subLinks || (link.useMegaMenu && link.megaMenu) ? 'has-sublinks' : ''
                      }`}
                    >
                      <Link to={link.url}>
                        <span>{link.title}</span>
                        {link.subLinks || (link.useMegaMenu && link.megaMenu) ? <CaretIcon /> : null}
                      </Link>
                      {link.subLinks && !link.useMegaMenu ? (
                        <ul className="sublinks">
                          {link.subLinks.map((subLink, i) => (
                            <li key={i}>
                              <Link to={subLink.url}>
                                <span
                                  className={`sublink ${i === 0 ? 'first' : ''}${
                                    i === link.subLinks.length - 1 ? 'last' : ''
                                  }`}
                                >
                                  <span>{subLink.title}</span>
                                </span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </div>
                    {link.useMegaMenu && link.megaMenu ? (
                      <MegaMenuWrapper>
                        <Row>
                          <Col as="ul" size={1} className="mega-menu-links-cont">
                            {link.megaMenu.subLinks.map((link, i) => {
                              if (!link.showLink) return null;
                              return (
                                <li key={i}>
                                  <Link className="mega-menu-link" to={link.url}>
                                    <FlexBox justify="space-between">
                                      <BodyTextAlt>{link.title}</BodyTextAlt> <ArrowIcon />
                                    </FlexBox>
                                  </Link>
                                </li>
                              );
                            })}
                          </Col>
                          <Col size={2} className="tout-cont">
                            <FlexBox>
                              <Link className="tout big-tout" to={link.megaMenu.toutOneLink}>
                                <FlexBox>
                                  <Image
                                    fluid={link.megaMenu.toutOneImage.fluid}
                                    alt={link.megaMenu.toutOneText}
                                  />
                                  <FlexBox
                                    className="tout-info p-b"
                                    direction="column"
                                    justify="space-between"
                                  >
                                    <Heading>{link.megaMenu.toutOneText}</Heading>
                                    <FlexBox align="center">
                                      <BodyTextAlt md="0 1rem 0 0">Shop Now</BodyTextAlt>{' '}
                                      <WhiteButton as="span">
                                        <ArrowIcon />
                                      </WhiteButton>
                                    </FlexBox>
                                  </FlexBox>
                                </FlexBox>
                              </Link>
                              <div className="little-touts">
                                <Link to={link.megaMenu.toutTwoLink}>
                                  <FlexBox className="tout little-tout">
                                    <Image
                                      fluid={link.megaMenu.toutTwoImage.fluid}
                                      alt={link.megaMenu.toutTwoText}
                                    />
                                    <FlexBox
                                      className="tout-info p-b"
                                      direction="column"
                                      justify="space-between"
                                    >
                                      <Heading>{link.megaMenu.toutTwoText}</Heading>
                                      <FlexBox align="center">
                                        <BodyTextAlt md="0 1rem 0 0">Shop Now</BodyTextAlt>{' '}
                                        <WhiteButton as="span">
                                          <ArrowIcon />
                                        </WhiteButton>
                                      </FlexBox>
                                    </FlexBox>
                                  </FlexBox>
                                </Link>
                                <Link to={link.megaMenu.toutThreeLink} style={{ marginBottom: '0' }}>
                                  <FlexBox className="tout little-tout">
                                    <Image
                                      fluid={link.megaMenu.toutThreeImage.fluid}
                                      alt={link.megaMenu.toutThreeText}
                                    />
                                    <FlexBox
                                      className="tout-info p-t"
                                      direction="column"
                                      justify="space-between"
                                    >
                                      <Heading>{link.megaMenu.toutThreeText}</Heading>
                                      <FlexBox align="center">
                                        <BodyTextAlt md="0 1rem 0 0">Shop Now</BodyTextAlt>{' '}
                                        <WhiteButton as="span">
                                          <ArrowIcon />
                                        </WhiteButton>
                                      </FlexBox>
                                    </FlexBox>
                                  </FlexBox>
                                </Link>
                              </div>
                            </FlexBox>
                          </Col>
                        </Row>
                      </MegaMenuWrapper>
                    ) : null}
                  </li>
                );
              })}
              <li className="pick-up-link">
                <span>{headerLinks.buttonLabel}</span>
              </li>
            </Default>
            <li>
              <span
                css={css`
                  display: inline-block;
                  @media ${breakpoint.lg} {
                    margin: 0.5rem 0;
                  }
                `}
              >
                <RedButton
                  as={Link}
                  to={headerLinks.buttonLink}
                  onClick={closeMenu}
                  className="no-stroke"
                >
                  {headerLinks.buttonText}
                </RedButton>
              </span>
            </li>
          </FlexBox>
        </nav>
        <Mobile>
          <SidebarMenu headerLinks={headerLinks} policyLinks={policyLinks} />
        </Mobile>
      </div>
    </MainMenuWrapper>
  );
};

export default MainMenu;
