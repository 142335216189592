import { useContext, useEffect } from 'react';
import StoreContext from '~/layouts/StoreContext';

const DeriveHeaderClasses = ({ location }) => {
    const { setHeaderWhite } = useContext(StoreContext);
    useEffect(() => {
        const pathname = location?.pathname || '';
        setHeaderWhite(
            pathname.includes('shop') ||
                pathname.includes('collections') ||
                pathname.includes('collection-list') ||
                pathname.includes('careers') ||
                pathname.includes('contact') ||
                pathname.includes('catering') ||
                pathname.includes('franchise') ||
                pathname.includes('mobile-app') ||
                pathname.includes('our-story') ||
                pathname.includes('press') ||
                pathname.includes('recipes') ||
                pathname.includes('safety') ||
                pathname.includes('locations/details')
        );
    }, [location]);
    return null;
};
export default DeriveHeaderClasses;
