export const rte = {
    foreColor: `#2e3233`,
    secondaryForeColor: `#5c6566`,
    backColor: `#fff`,
    secondaryBackColor: `#edf2f2`,
    blockquoteColor: `#f57c00`,
    preColor: `#1565c0`,
    borderColor: `#e2e4e4`,
    secondaryBorderColor: `#c4cbcc`,
    headingRatio: 1.19,
    universalMargin: `1rem`,
    universalPadding: `1rem`,
    universalBorderRadius: `0.125rem`,
    aLinkColor: `#d4212c`,
    aVisitedColor: `#cc5f66`,
}