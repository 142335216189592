import React, { useState } from 'react';
import Image from 'gatsby-image';
import { getImage } from '~/helpers';
import { Heading, BodyText } from '~/components/.base/headings';
import { FlexBox, BackgroundDivBgImage } from '~/components/.base/containers';
import { SearchForm } from '~/components/.base/inputs';
import './Newsletter.scss';
import { subscribeToEmailList } from '~/helpers/requests/customer-requests';
import { WoodenBorder } from '../../.base/border';
import { TextField } from '../../.base/inputs';

const Newsletter = ({ footerImages }) => {
  const [email, setEmail] = useState('');
  const [visibleWelcome, setVisibleWelcome] = useState(false);

  const handleSignUp = async event => {
    event.preventDefault();
    event.target.querySelector('[type="submit"]').style.cursor = 'not-allowed';
    event.target.querySelector('[type="submit"]').setAttribute('disabled', true);

    const { status } = await subscribeToEmailList(email, true, true);

    if (status === 201) {
      setVisibleWelcome(true);
    }

    setTimeout(() => {
      event.target.querySelector('[type="submit"]').removeAttribute('disabled');
      event.target.querySelector('[type="submit"]').style.cursor = '';
      setVisibleWelcome(false);
      setEmail('');
    }, 5000);
  };

  return (
    <FlexBox className="newsletter" justify="space-between" align="center">
      <WoodenBorder color="blue" top="0" topLg="23%" tablet zIndex="-1" />
      <BackgroundDivBgImage
        w="105%"
        h="77%"
        top="23%"
        left="-5%"
        color="#edf2f2"
        style={{ zIndex: '-2' }}
        tablet
        topMobile="3%"
        heightMobile="97%"
      />
      <Heading>
        Join our
        <br /> <span className="red">Mailing List</span>
      </Heading>
      <Heading className="mobile">
        Signup for <br /> everything
        <span className="red"> CML!</span>
      </Heading>
      <Image fluid={getImage(footerImages, 'jim-sabin-trap-copy')} alt="" className="trappin-jim" />
      <BodyText>
        Stay upwind of all the latest Cousins Maine Lobster news, special offers, promotions, &amp;
        special content available only to our mailing list
      </BodyText>

      <SearchForm
        as="form"
        className="search-form"
        w="450px"
        id="email_signup"
        onSubmit={handleSignUp}
      >
        <input type="hidden" name="g" value="VNPyv9" />
        <TextField
          label="Enter your email..."
          htmlFor="newsletterEmail"
          name="email"
          type="email"
          autocomplete="email"
          required
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <button type="submit">Subscribe Now</button>

        {visibleWelcome && (
          <p className="success" role="alert">
            Welcome to the family!
          </p>
        )}
      </SearchForm>
    </FlexBox>
  );
};

export default Newsletter;
