import React, { useState, useEffect } from 'react';
import Client from 'shopify-buy';

import Context from './StoreContext';
import { checkDiscount } from 'helpers/requests/discount-code-requests';

const client = Client.buildClient({
  storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  domain: `${process.env.GATSBY_SHOP_NAME}.myshopify.com`,
});

const ContextProvider = ({ asyncScriptLoad, children }) => {
  let initialStoreState = {
    client,
    adding: false,
    checkout: {
      lineItems: [],
      appliedDiscount: '',
      shippingAddress: {},
    },
    trueLinePrices: [],
    trueLineItemsSubtotalNoDiscounts: 0,
    trueLineItemsSubtotal: 0,
    trueLineItemsBasePriceSubtotalNoDiscounts: 0,
    products: [],
    shop: {},
    whiteHeader: false,
    sidebarCartOpen: false,
    sidebarMenuOpen: false,
    activeCustomer: {},
    shippingActive: true,
    // This variable (lobsterTitle) is the current title of the line item inside the graphQL model
    // It's used to prevent the subtracting of lobster items who's quantity is at 2 since there are a minimum of 2 lobsters per order
    lobsterTitle: 'Live Lobsters (Minimum 2)',
    lobsterSubscriptionTitle: 'Live Lobsters (Minimum 2) Subscription',
    loadingBarProgress: 0,
    isLineItemUpdating: false,
    asyncScriptLoad,
    discountType: null,
    discountAmount: 0,
  };

  const [store, updateStore] = useState(initialStoreState);

  useEffect(() => {
    const initializeCheckout = async () => {
      // Check for an existing cart.
      const isBrowser = typeof window !== 'undefined';
      const existingCheckoutID = isBrowser ? localStorage.getItem('shopify_checkout_id') : null;

      const setCheckoutInState = checkout => {
        if (isBrowser) {
          localStorage.setItem('shopify_checkout_id', checkout.id);
        }

        updateStore(prevState => {
          return { ...prevState, checkout };
        });
      };

      const createNewCheckout = () => store.client.checkout.create();
      const fetchCheckout = id => store.client.checkout.fetch(id);
      if (existingCheckoutID) {
        try {
          const checkout = await fetchCheckout(existingCheckoutID);
          // Make sure this cart hasn’t already been purchased.
          if (!checkout.completedAt) {
            setCheckoutInState(checkout);
            return;
          }
        } catch (e) {
          localStorage.setItem('shopify_checkout_id', null);
        }
      }
      const newCheckout = await createNewCheckout();
      setCheckoutInState(newCheckout);
    };
    initializeCheckout();
  }, [store.client.checkout]);

  useEffect(() => {
    updateStore(prevState => {
      return { ...prevState, asyncScriptLoad };
    });
  }, [asyncScriptLoad]);

  useEffect(() => {
    updateStore(prevState => {
      // function that sets prices basde on upsells, quantity discounts, etc.
      let newLinePrices = prevState.checkout.lineItems.map(line_item => {
        const isSubscription = !!line_item.customAttributes.find(
          attr => attr.key === 'subscription_interval'
        );
        const isApparel = !!line_item.variant.sku.includes('APP-');
        const isGiftCard = !!line_item.variant.sku.includes('GIFT-');
        const linePrice = Number(line_item.variant.price.amount);
        const hasDiscount = !!line_item.discountAllocations.length && !isGiftCard;
        const { quantity } = line_item;
        // looks for upsell product line item property and applies the upsell discount
        if (line_item.customAttributes.find(attr => attr.key === 'upsell_product')) {
          const upsellDiscount = Number(
            line_item.customAttributes.find(attr => attr.key === 'upsell_discount').value
          );
          const discountType = line_item.customAttributes.find(
            attr => attr.key === 'discount_type'
          ).value;
          if (discountType === 'Percent Discount') {
            return {
              // accurate price after discounts
              price: (linePrice - (linePrice * upsellDiscount) / 100) * quantity,
              // price before discounts
              // because subscriptions (10% discount) are already reflected in the line price, have to multiply base price by 10/9
              basePrice: isSubscription ? ((linePrice * 10) / 9) * quantity : linePrice * quantity,
              subscriptionPrice: linePrice * quantity,
              unitPrice: linePrice - (linePrice * upsellDiscount) / 100,
              baseUnitPrice: isSubscription ? (linePrice * 10) / 9 : linePrice,
              quantity,
              isSubscription,
              isApparel,
              isGiftCard,
              upsellDiscountInEffect: true,
              discountType,
              upsellDiscount,
              hasDiscount,
            };
          } else if (discountType === 'Absolute Discount') {
            return {
              price: (linePrice - upsellDiscount) * quantity,
              basePrice: isSubscription ? ((linePrice * 10) / 9) * quantity : linePrice * quantity,
              subscriptionPrice: linePrice * quantity,
              unitPrice: linePrice - upsellDiscount,
              baseUnitPrice: isSubscription ? (linePrice * 10) / 9 : linePrice,
              quantity,
              isSubscription,
              isApparel,
              isGiftCard,
              upsellDiscountInEffect: true,
              discountType,
              hasDiscount,
              upsellDiscount,
            };
          }
          // looks for quantity discount line item property and applies the quantity discount
        } else if (
          line_item.customAttributes.find(attr => attr.key === 'quantity_discount_unit_price')
        ) {
          // every quantity discount is listed out in quantity_discount_unit_price, separated by commas.
          const quantityDiscountUnitPrices = line_item.customAttributes
            .find(attr => attr.key === 'quantity_discount_unit_price')
            .value.split(',');
          // every threshold is listed out in quantity_discount_threshold, separated by comma.
          // the price and threshold indexes correspond to each other.
          const thresholds = line_item.customAttributes
            .find(attr => attr.key === 'quantity_discount_threshold')
            .value.split(',');
          var activeDiscountIndex = 0,
            highestQuantityThreshold = 0,
            discountInEffect = false;
          // check to see if quantity is above threshold
          for (let i = 0; i < thresholds.length; i++) {
            if (quantity >= thresholds[i] && thresholds[i] > highestQuantityThreshold) {
              discountInEffect = true;
              activeDiscountIndex = i;
              highestQuantityThreshold = thresholds[i];
            }
          }
          if (discountInEffect) {
            return {
              price: isSubscription
                ? (9 / 10) * quantityDiscountUnitPrices[activeDiscountIndex] * quantity
                : quantityDiscountUnitPrices[activeDiscountIndex] * quantity,
              basePrice: isSubscription ? ((linePrice * 10) / 9) * quantity : linePrice * quantity,
              subscriptionPrice: linePrice * quantity,
              unitPrice: isSubscription
                ? (9 / 10) * quantityDiscountUnitPrices[activeDiscountIndex]
                : quantityDiscountUnitPrices[activeDiscountIndex],
              baseUnitPrice: isSubscription ? (linePrice * 10) / 9 : linePrice,
              isSubscription,
              isApparel,
              isGiftCard,
              quantity,
              hasDiscount,
              quantityDiscountInEffect: true,
            };
          } else {
            return {
              price: linePrice * quantity,
              basePrice: isSubscription ? ((linePrice * 10) / 9) * quantity : linePrice * quantity,
              subscriptionPrice: linePrice * quantity,
              unitPrice: linePrice,
              baseUnitPrice: isSubscription ? (linePrice * 10) / 9 : linePrice,
              quantity,
              isSubscription,
              isApparel,
              isGiftCard,
              hasDiscount,
            };
          }
        } else {
          return {
            price: linePrice * quantity,
            basePrice: isSubscription ? ((linePrice * 10) / 9) * quantity : linePrice * quantity,
            subscriptionPrice: linePrice * quantity,
            unitPrice: linePrice,
            baseUnitPrice: isSubscription ? (linePrice * 10) / 9 : linePrice,
            quantity,
            isSubscription,
            isApparel,
            isGiftCard,
            hasDiscount,
          };
        }
        return true;
      });
      // applies discount codes to line prices
      let baseSubtotal = newLinePrices.reduce((a, b) => a + b.price, 0),
        basePricesSubtotal = newLinePrices.reduce((a, b) => a + b.basePrice, 0),
        subscriptionPricesSubtotal = newLinePrices.reduce((a, b) => a + b.subscriptionPrice, 0),
        // separating apparel for free shipping threshold calculations
        baseSubtotalNoApparel = newLinePrices
          .filter(item => !item.isApparel)
          .reduce((a, b) => a + b.price, 0),
        discounts = 0;
      const { discountApplications } = prevState.checkout;
      if (discountApplications && discountApplications.length) {
        if (discountApplications[0].targetType === 'LINE_ITEM') {
          if (discountApplications[0].value.percentage) {
            discounts = 0;
            newLinePrices = newLinePrices.map(item => {
              if (item.hasDiscount) {
                discounts += (discountApplications[0].value.percentage / 100) * item.price;
                return {
                  ...item,
                  price: ((100 - discountApplications[0].value.percentage) / 100) * item.price,
                  code: discountApplications[0].code,
                  codeDiscountAmount: (discountApplications[0].value.percentage / 100) * item.price,
                };
              }
              if (item.isGiftCard) {
                // exclude gift cards from discount codes
                discounts += 0;
                return item;
              }

              discounts += 0;
              return item;
            });
          } else if (discountApplications[0].value.amount) {
            discounts = 0;
            newLinePrices = newLinePrices.map(item => {
              if (item.hasDiscount) {
                discounts += Number(discountApplications[0].value.amount);
                return {
                  ...item,
                  price: item.price - Number(discountApplications[0].value.amount),
                  code: discountApplications[0].code,
                  codeDiscountAmount: Number(discountApplications[0].value.amount),
                };
              }
              if (item.isGiftCard) {
                // exclude gift cards from discount codes
                discounts += 0;
                return item;
              }

              discounts += 0;
              return item;
            });
          }
        }
      }
      return {
        ...prevState,
        trueLinePrices: newLinePrices,
        trueLineItemsSubtotalNoDiscounts: baseSubtotal,
        trueLineItemsSubtotalNoDiscountsNoApparel: baseSubtotalNoApparel,
        trueLineItemsSubtotal: baseSubtotal - discounts,
        trueLineItemsBasePriceSubtotalNoDiscountsSubscriptionsIncluded: subscriptionPricesSubtotal,
        trueLineItemsBasePriceSubtotalNoDiscounts: basePricesSubtotal,
      };
    });
  }, [store.checkout.discountApplications, store.checkout.lineItems, store.loadingBarProgress]);

  /* useEffect(() => {
    (async () => {
      if (typeof window !== 'undefined') {
        const params = new URLSearchParams(window.location.search);
        if (params.get('discount_code')) {
          const res = await checkDiscount(params.get('discount_code'));
          if (res?.data) {
            const discountResponse = res.data;
            if (discountResponse?.type && discountResponse?.status === 'ACTIVE') {
              // Remove previous discount
              console.log('remove previous discount');
              const checkout = await store?.client?.checkout?.removeDiscount(
                localStorage.getItem('shopify_checkout_id')
              );
              checkout.appliedDiscount = '';
              updateStore(prevState => {
                return {
                  ...prevState,
                  checkout,
                  discountType: null,
                  discountAmount: 0,
                  linkDiscountCode: null,
                  productHandlers: [],
                  collectionHandlers: [],
                };
              });
            }
          }
        }
      }
    })();
  }, []); */

  useEffect(() => {
    (async () => {
      if (typeof window !== 'undefined') {
        const params = new URLSearchParams(window.location.search);
        if (params.get('discount_code') || store.checkout?.discountApplications?.length) {
          const discountCode =
            params.get('discount_code') || store?.checkout?.discountApplications?.[0]?.code || null;

          if (discountCode) {
            let res = null;

            try {
              res = await checkDiscount(discountCode);
            } catch (e) {
              console.log('checkDiscount error', e);
            }

            if (res?.data) {
              const discountResponse = res.data;
              if (discountResponse?.type && discountResponse?.status === 'ACTIVE') {
                const discountType = discountResponse.type;
                updateStore(prevState => {
                  return {
                    ...prevState,
                    // checkout,
                    discountType: discountType === 'DiscountPercentage' ? 'Percentage' : 'Absolute',
                    discountAmount:
                      discountType === 'DiscountPercentage'
                        ? Number(discountResponse.value) * 100
                        : Number(discountResponse.value),
                    linkDiscountCode: discountResponse.code,
                    productHandlers: discountResponse.productHandlers,
                    collectionHandlers: discountResponse.collectionHandlers,
                    ...(discountResponse?.AllDiscountItems && {
                      AllDiscountItems: discountResponse.AllDiscountItems,
                    }),
                  };
                });
              }
            }
          }
        }
      }
    })();
  }, [store.checkout.discountApplications]);

  return (
    <Context.Provider
      value={{
        store,
        updateCheckoutWithAbandonedCart: (lineItemsToUpdate, id) => {
          updateStore(prevState => {
            return { ...prevState, adding: true };
          });
          return client.checkout
            .updateLineItems(id, lineItemsToUpdate)
            .then(checkout => {
              updateStore(prevState => {
                return { ...prevState, checkout, adding: false };
              });
            })
            .catch(err => console.log(err));
        },
        updateLoadingBarProgress: progress => {
          updateStore(prevState => {
            return { ...prevState, loadingBarProgress: progress };
          });
        },
        addVariantToCart: (variantId, quantity, customAttributes, gaObj) => {
          if (variantId === '' || !quantity) {
            return;
          }
          var lineItems;
          updateStore(prevState => {
            lineItems = prevState?.checkout?.lineItems;
            return { ...prevState, adding: true, loadingBarProgress: 40 };
          });

          const { checkout, client } = store;

          const checkoutId = checkout.id;
          const lineItemsToUpdate = [
            {
              variantId,
              quantity: parseInt(quantity, 10),
              customAttributes,
            },
          ];
          return client.checkout.addLineItems(checkoutId, lineItemsToUpdate).then(checkout => {
            updateStore(prevState => {
              if (prevState?.linkDiscountCode && lineItems?.length === 0) {
                client.checkout
                  .addDiscount(checkoutId, prevState.linkDiscountCode)
                  .then(checkout => {
                    updateStore(prevState => {
                      return { ...prevState, checkout, adding: false, loadingBarProgress: 100 };
                    });
                  });
              }
              return {
                ...prevState,
                checkout,
                adding: false,
                loadingBarProgress: 100,
              };
            });
            if (gaObj && window.ga) {
              window.ga('require', 'ec');
              window.ga('ec:addProduct', gaObj);
              window.ga('ec:setAction', 'add');
              window.ga('send', 'event', 'UX', 'click', 'add to cart');
            }
            if (window.fbq) {
              window.fbq('track', 'AddToCart');
            }
          });
        },
        removeBaseLineItem: async (client, checkoutID, lineItemID) => {
          updateStore(prevState => {
            return { ...prevState, loadingBarProgress: 40 };
          });
          return client.checkout.removeLineItems(checkoutID, [lineItemID]).then(res => {
            updateStore(prevState => {
              return { ...prevState, checkout: res, loadingBarProgress: 100 };
            });
          });
        },
        removeLineItem: async (client, checkoutID, lineItemID) => {
          const lineItem = store.checkout.lineItems.find(item => item.id === lineItemID);
          const varId = lineItem.variant.id;
          let linkDiscountCode;
          let lineItems;
          updateStore(prevState => {
            linkDiscountCode = prevState?.linkDiscountCode;
            lineItems = prevState?.checkout?.lineItems;
            return { ...prevState, loadingBarProgress: 40 };
          });
          const upsellsToRemove =
            store.checkout.lineItems
              .filter(item => {
                const upsellAttr = item.customAttributes.find(
                  attr => attr.key === 'base_product_for_upsell_var_id'
                );
                return upsellAttr && upsellAttr.value === varId;
              })
              ?.map(item => item.id) || [];

          const lineItemsToRemove = [lineItemID, ...upsellsToRemove];

          if (linkDiscountCode && lineItems.length === lineItemsToRemove.length) {
            client.checkout.removeDiscount(checkoutID).then(checkout => {
              client.checkout
                .removeLineItems(checkoutID, [...lineItemsToRemove])
                .then(res => {
                  if (window.ga) {
                    window.ga('ec:addProduct', {
                      name: lineItem.title,
                      id: lineItem.variant.id,
                      price: lineItem.variant.price.amount,
                      brand: 'CML',
                      variant: lineItem.variant.title,
                      quantity: lineItem.quantity,
                    });
                    window.ga('ec:setAction', 'remove');
                    window.ga('send', 'event', 'UX', 'click', 'remove from cart');
                  }
                  updateStore(prevState => {
                    return {
                      ...prevState,
                      checkout: res,
                      loadingBarProgress: 100,
                    };
                  });
                })
                .catch(err => console.log(err, lineItemID, lineItem));
            });
          } else {
            client.checkout
              .removeLineItems(checkoutID, [...lineItemsToRemove])
              .then(res => {
                updateStore(prevState => ({
                  ...prevState,
                  checkout: res,
                  loadingBarProgress: 100,
                }));
                if (window.ga) {
                  window.ga('ec:addProduct', {
                    name: lineItem.title,
                    id: lineItem.variant.id,
                    price: lineItem.variant.price.amount,
                    brand: 'CML',
                    variant: lineItem.variant.title,
                    quantity: lineItem.quantity,
                  });
                  window.ga('ec:setAction', 'remove');
                  window.ga('send', 'event', 'UX', 'click', 'remove from cart');
                }
              })
              .catch(err => console.log(err, lineItemID, lineItem));
          }
        },
        updateLineItem: (client, checkoutID, lineItemID, quantity) => {
          updateStore(prevState => {
            return { ...prevState, loadingBarProgress: 40, isLineItemUpdating: true };
          });
          const lineItem = store.checkout.lineItems.find(item => item.id === lineItemID);
          const varId = lineItem.variant.id;

          const upsellsToUpdate = store.checkout.lineItems.filter(item => {
            const upsellAttr = item.customAttributes.find(
              attr => attr.key === 'base_product_for_upsell_var_id'
            );
            const upsellDiscountAmout = item.customAttributes.find(
              attr => attr.key === 'upsell_discount'
            )?.value;
            const upsellDiscountType = item.customAttributes.find(
              attr => attr.key === 'discount_type'
            )?.value;
            return (
              upsellAttr &&
              upsellAttr.value === varId &&
              ((upsellDiscountAmout === '100' && upsellDiscountType === 'Percent Discount') ||
                item.quantity > quantity)
            );
          });

          const lineItemsToUpdate = [
            { id: lineItemID, quantity: parseInt(quantity, 10) },
            ...upsellsToUpdate.map(upsellItem => ({
              id: upsellItem.id,
              quantity: parseInt(quantity, 10),
            })),
          ];

          return client.checkout.updateLineItems(checkoutID, lineItemsToUpdate).then(res => {
            updateStore(prevState => {
              return {
                ...prevState,
                checkout: res,
                loadingBarProgress: 100,
                isLineItemUpdating: false,
              };
            });
          });
        },
        updateShippingAddress: (client, checkoutID, addressValues) => {
          return client.checkout.updateShippingAddress(checkoutID, addressValues).then(checkout => {
            updateStore(prevState => {
              return { ...prevState, checkout };
            });
          });
        },
        applyDiscount: (client, checkoutID, discountCode) => {
          console.log('applyDiscount');
          updateStore(prevState => {
            return { ...prevState, loadingBarProgress: 40 };
          });
          return client.checkout.addDiscount(checkoutID, discountCode).then(checkout => {
            updateStore(prevState => {
              return { ...prevState, checkout, loadingBarProgress: 100 };
            });
          });
        },
        removeDiscount: (client, checkoutID) => {
          updateStore(prevState => {
            return { ...prevState, loadingBarProgress: 40 };
          });
          client.checkout.removeDiscount(checkoutID).then(checkout => {
            checkout.appliedDiscount = '';
            updateStore(prevState => {
              return {
                ...prevState,
                checkout,
                discountType: null,
                discountAmount: 0,
                linkDiscountCode: null,
                productHandlers: [],
                collectionHandlers: [],
                loadingBarProgress: 100,
              };
            });
          });
        },
        openMenu: val => {
          updateStore(prevState => {
            return { ...prevState, sidebarMenuOpen: true };
          });
        },
        closeMenu: val => {
          updateStore(prevState => {
            return { ...prevState, sidebarMenuOpen: false };
          });
        },
        openCart: val => {
          updateStore(prevState => {
            return { ...prevState, sidebarCartOpen: true };
          });
        },
        closeCart: val => {
          updateStore(prevState => {
            return { ...prevState, sidebarCartOpen: false };
          });
        },
        addCustomer: val => {
          updateStore(prevState => {
            return { ...prevState, activeCustomer: val };
          });
        },
        setHeaderWhite: val => {
          updateStore(prevState => {
            return { ...prevState, whiteHeader: val };
          });
        },
        stateChangeHandler: newState => {
          updateStore(prevState => {
            return {
              ...prevState,
              sidebarCartOpen: newState.sidebarCartOpen,
              sidebarMenuOpen: newState.sidebarCartOpen,
            };
          });
        },
        setShippingActive: () => {
          updateStore(prevState => {
            return {
              ...prevState,
              shippingActive: !prevState.shippingActive,
            };
          });
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};
export default ContextProvider;
