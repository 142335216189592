import React, { useContext } from 'react';
import { Link } from 'gatsby';
import StoreContext from '~/layouts/StoreContext';
import Drawer from '@material-ui/core/Drawer';
import Collapsible from 'react-collapsible';
import { BodyTextAlt, Heading, Subheading } from '~/components/.base/headings';
import { WhiteButton } from '~/components/.base/buttons';
import { FlexBox } from '~/components/.base/containers';
import HomeIcon from '~/images/icons/homepage.svg';

import './SidebarMenuEcomm.scss';

const SidebarMenuEcomm = ({ visibleHeaderLinks, policyLinks }) => {
  const {
    store: { sidebarMenuOpen },
    closeMenu,
  } = useContext(StoreContext);
  return (
    <Drawer
      anchor="left"
      open={sidebarMenuOpen}
      onClose={closeMenu}
      className={'sidebar-menu relative'}
    >
      <FlexBox
        as="nav"
        className="menu-inner"
        direction="column"
        align="center"
        justify="space-between"
      >
        <ul className="collapsibles">
          {visibleHeaderLinks.map((link, i) => {
            if (link.subLinks) {
              return (
                <li>
                  <Collapsible trigger={link.title} key={i}>
                    <div className="menu-item">
                      {link.subLinks.map((subLink, j) => (
                        <Link key={j} to={subLink.url} onClick={closeMenu}>
                          <Heading>{subLink.title}</Heading>
                        </Link>
                      ))}
                      <Link to={link.url} onClick={closeMenu}>
                        <Heading>All {link.title}</Heading>
                      </Link>
                    </div>
                  </Collapsible>
                </li>
              );
            } else {
              return (
                <li>
                  <Link key={i} to={link.url} onClick={closeMenu}>
                    <div className="mobile-header-link">
                      <BodyTextAlt color="#d4212c">{link.title}</BodyTextAlt>
                    </div>
                  </Link>
                </li>
              );
            }
          })}
          {policyLinks?.links?.length > 0 && (
            <li className="home-link">
              <ul>
                {policyLinks.links.map((link, i) => (
                  <li key={i}>
                    <Link key={i} to={link.url} onClick={closeMenu}>
                      <Subheading className="text--subdued">{link.title}</Subheading>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          )}
        </ul>
        <FlexBox className="home-link" align="center">
          <FlexBox as={Link} to="/" onClick={closeMenu} align="center">
            <WhiteButton as="span" className="no-stroke" pd="0" h="40px" w="40px">
              <HomeIcon />
            </WhiteButton>
            <BodyTextAlt>Go to main site</BodyTextAlt>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Drawer>
  );
};
export default SidebarMenuEcomm;
