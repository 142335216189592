import styled from 'styled-components';
import { rte } from '~/constants/theme';

const {
    foreColor,
    secondaryForeColor,
    backColor,
    secondaryBackColor,
    blockquoteColor,
    preColor,
    borderColor,
    secondaryBorderColor,
    headingRatio,
    universalMargin,
    universalPadding,
    universalBorderRadius,
    aLinkColor,
    aVisitedColor,
} = rte;

export const HeadingLarge = styled.h1`
    margin: ${props => props.md};
    text-align: ${props => props.align};
    font-family: Tiempos Text, serif;
    font-size: 48px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.92;
    letter-spacing: normal;
    color: #2e3233;
    color: ${props => props.color};
    text-transform: uppercase;
    .white {
        color: #fff;
    }
    .red {
        color: #d4212c;
    }
    .indent {
        margin-left: 5rem;
    }
    @media screen and (max-width: 767px) {
        font-size: 36px;
        letter-spacing: 0.5px;
        .indent {
            margin-left: 0;
        }
    }
`;

export const Heading = styled.h2`
    margin: ${props => props.md};
    text-align: ${props => props.align};
    text-transform: ${props => props.textTransform};
    font-family: 'Tiempos Text';
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.5px;
    color: #2e3233;
    color: ${props => props.color};
    .red {
        color: #d4212c;
    }
`;

export const HeadingXL = styled(HeadingLarge)`
    font-size: 76px;
    line-height: 0.75;
`;
export const HeadingMegaXL = styled(HeadingLarge)`
    font-size: 140px;
    line-height: 0.75;
`;
export const Subheading = styled.h3`
    display: block;
    margin: ${props => props.md};
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${props => props.color || '#5c6566'};
    .red {
        color: #d4212c;
    }
`;

export const Rte = styled.div`
	font-family: Montserrat, sans-serif;
  padding-bottom: calc(100px + ${universalPadding});
  text-align: justify;
  word-wrap: break-word;
  white-space: pre-wrap;
  a,
  b,
  del,
  em,
  i,
  ins,
  q,
  span,
  strong,
  u {
    font-size: 1em;
  }
  details {
    display: block;
  }
  summary {
    display: list-item;
  }

  abbr[title] {
    border-bottom: none;
    text-decoration: underline dotted;
  }

  input {
    overflow: visible;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.2;
    margin: calc(1.5 * ${universalMargin}) 0;
    font-weight: 500;
    font-family: 'Tiempos Text',serif;
  }

  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    color: ${secondaryForeColor};
    display: block;
    margin-top: -0.25rem;
  }

  h1 {
    font-size: calc( 1rem * ${headingRatio} * ${headingRatio} * ${headingRatio} *
        ${headingRatio} px);
  }

  h2 {
    font-size: calc(
      1rem * ${headingRatio} * ${headingRatio} * ${headingRatio}
    );
  }

  h3 {
    font-size: calc(1rem * ${headingRatio} * ${headingRatio});
  }

  h4 {
    font-size: calc(1rem * ${headingRatio});
  }

  h5 {
    font-size: 1rem;
  }

  h6 {
    font-size: calc(1rem / ${headingRatio});
  }

  p {
    margin: ${universalMargin} 0;
  }

  ol,
  ul {
    margin: ${universalMargin} 0;
    padding-left: calc(2 * ${universalMargin});
  }

  b,
  strong {
    font-weight: 700;
  }

  hr {
    box-sizing: content-box;
    border: 0;
    line-height: 1.25em;
    margin: ${universalMargin} 0;
    height: 0.0625rem;
    background: linear-gradient(
      to right,
      transparent,
      var($border-color) 20%,
      var($border-color) 80%,
      transparent
    );
  }

  blockquote {
    display: block;
    position: relative;
    font-style: italic;
    color: ${secondaryForeColor};
    margin: ${universalMargin} 0;
    padding: calc(3 * ${universalPadding});
    border: 0.0625rem solid ${secondaryBorderColor};
    border-left: 0.375rem solid ${blockquoteColor};
    border-radius: 0 ${universalBorderRadius}
      ${universalBorderRadius} 0;
  }

  blockquote:before {
    position: absolute;
    left: 0;
    font-family: sans-serif;
    font-size: 3rem;
    font-weight: 700;
    content: "\201c";
    color: ${blockquoteColor};
    top: -${universalPadding};
  }

  blockquote[cite]:after {
    font-style: normal;
    font-size: 0.75em;
    font-weight: 700;
    content: "\a—  "attr(cite);
    white-space: pre;
  }

  code,
  kbd,
  pre,
  samp {
    font-family: Menlo, Consolas, monospace;
    font-size: 0.85em;
  }

  code {
    background: ${secondaryBackColor};
    border-radius: ${universalBorderRadius};
    padding: calc(${universalPadding} / 4)
      calc(${universalPadding} / 2);
  }

  kbd {
    background: ${foreColor};
    color: ${backColor};
    border-radius: ${universalBorderRadius};
    padding: calc(${universalPadding} / 4)
      calc(${universalPadding} / 2);
  }

  pre {
    overflow: auto;
    background: ${secondaryBackColor};
    padding: calc(1.5 * ${universalPadding});
    margin: ${universalMargin} 0;
    border: 0.0625rem solid ${secondaryBorderColor};
    border-left: 0.25rem solid ${preColor};
    border-radius: 0 ${universalBorderRadius}
      ${universalBorderRadius} 0;
  }

  sup,
  sub,
  code,
  kbd {
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  small,
  sup,
  sub,
  figcaption {
    font-size: 0.75em;
  }

  sup {
    top: -0.5em;
  }

  sub {
    bottom: -0.25em;
  }

  figure {
    margin: ${universalMargin} 0;
  }

  figcaption {
    color: ${secondaryForeColor} 0;
  }

  a {
    text-decoration: none;
  }

  a:link {
    color: ${aLinkColor};
  }

  a:visited {
    color: ${aVisitedColor};
  }

  a:hover,
  a:focus {
    text-decoration: underline;
  }
`;

export const BodyText = styled.p`
    margin: ${props => props.md};
    text-align: ${props => props.align};
    font-family: TimesNewRomanPSMT, serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #5c6566;
    color: ${props => props.color};
    text-indent: ${({ textIndent }) => textIndent};
    .indent {
        margin-left: 5rem;
    }
`;
export const BodyTextAlt = styled.p`
    margin: ${props => props.md};
    text-align: ${props => props.align};
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #5c6566;
    color: ${props => props.color};
    .red {
        color: #d4212c;
    }
`;

export const LargeStencil = styled.h3`
    -webkit-text-stroke: 1px #d4212c;
    font-family: 'Tiempos Text';
    font-size: 450px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -15px;
    color: rgba(212, 33, 44, 0);
    position: absolute;
    text-transform: uppercase;
    white-space: nowrap;
`;

export const SolidStencil = styled.h3`
    font-family: 'Tiempos Text';
    font-size: 600px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: -20px;
    margin-left: -100px;
    color: #d4212c;
    text-transform: uppercase;
    white-space: nowrap;
    position: absolute;
`;
