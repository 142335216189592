import React, { useCallback, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import { useMediaQuery } from 'react-responsive';

import { size } from '~/constants/Breakpoint';
import { Newsletter } from '~/components/Navigation';
import { FlexBox } from '~/components/.base/containers';
import InstagramIcon from '~/images/icons/social/instagram.svg';
import YoutubeIcon from '~/images/icons/social/you-tube.svg';
import TwitterIcon from '~/images/icons/social/twitter.svg';
import './Footer.scss';

const Footer = ({ hideSocialIcons, footerImages, footerData }) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${size.lg - 1}px)` });

  useEffect(() => {
    if (window && !isMobile) {
      var lastScrollTop = 0;
      var footer = document.querySelector('#sticky-footer');
      var anchor = document.getElementById('___gatsby');
      const footerScroll = function () {
        var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
        if (st < 92) {
          footer.classList.remove('hidden');
        } else {
          if (st > lastScrollTop) {
            footer.classList.add('hidden');
          } else {
            footer.classList.remove('hidden');
          }
        }
        if (st > anchor.getBoundingClientRect().height - window.innerHeight - 900) {
          footer.classList.add('bottom');
        } else {
          if (footer.classList.contains('bottom')) {
            footer.classList.remove('bottom');
            footer.classList.add('hidden');
            window.setTimeout(() => {
              footer.classList.remove('hidden');
            }, 300);
          }
        }
        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
      };
      window.addEventListener('scroll', footerScroll, false);
      return function cleanup() {
        window.removeEventListener('scroll', footerScroll);
      };
    }
  }, []);
  const footerRef = useRef(null);
  const handleFooterHeight = useCallback(() => {
    if (footerRef?.current) {
      document.documentElement.style.setProperty(
        '--footer-height',
        footerRef.current.offsetHeight + 'px'
      );
    }
  }, [footerRef]);
  useEffect(() => {
    // set resize listener
    window.addEventListener('resize', handleFooterHeight);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', handleFooterHeight);
    };
  }, [handleFooterHeight]);
  return (
    <footer>
      <Newsletter footerImages={footerImages} />
      <div ref={footerRef} id="footer" className="footer desktop d-flex bottom">
        <FlexBox as="ul" align="center" className="left-links">
          {footerData.links.map(({ url, title, cssClass }, i) => (
            <li key={i}>
              <Link className="footer-link" to={url}>
                <span className={cssClass}>{title}</span>
              </Link>
            </li>
          ))}
        </FlexBox>
        <FlexBox align="center" className="right-links">
          {hideSocialIcons ? null : (
            <ul className="icons d-flex">
              {!hideSocialIcons && (
                <>
                  <li>
                    <a
                      className="footer-link footer-icon"
                      target="_blank"
                      rel="noreferrer"
                      href={footerData.instagramLink}
                      aria-label="Instagram"
                    >
                      <InstagramIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      className="footer-link footer-icon"
                      target="_blank"
                      rel="noreferrer"
                      href={footerData.youtubeLink}
                      aria-label="Youtube"
                    >
                      <YoutubeIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      className="footer-link footer-icon"
                      target="_blank"
                      rel="noreferrer"
                      href={footerData.twitterLink}
                      aria-label="Twitter"
                    >
                      <TwitterIcon />
                    </a>
                  </li>
                </>
              )}
              <li className="app-linkWrapper">
                <Link className="footer-link app-link" to={footerData.getTheAppLink}>
                  Get the App
                </Link>
              </li>
            </ul>
          )}
        </FlexBox>
      </div>
      <div ref={footerRef} className="footer requirement-footer desktop d-flex bottom">
        <FlexBox as="ul" align="left" className="left-links requirement-links">
          {footerData.caRequirementLinks.map(({ url, title, cssClass }, i) => {
            if (title?.toLowerCase().includes('consent')) {
              return (
                <li key={i}>
                  <a
                    id="termly-consent-preferences"
                    className="footer-link"
                    onClick={() => {
                      window?.displayPreferenceModal();
                      return false;
                    }}
                  >
                    <span className="text--subdued">{title}</span>
                  </a>
                </li>
              )
            }

            return (
              <li key={i}>
                <a
                  className="footer-link"
                  href={url}
                  target='_blank'
                >
                  <span className={cssClass}>{title}</span>
                </a>
              </li>
            )
          })}
        </FlexBox>
      </div>
      <div id="sticky-footer" className="footer sticky desktop d-flex">
        <FlexBox as="ul" align="center" className="left-links">
          {footerData.links.map(({ url, title, cssClass }, i) => (
            <li key={i}>
              <Link className="footer-link" to={url}>
                <span className={cssClass}>{title}</span>
              </Link>
            </li>
          ))}
        </FlexBox>
        <FlexBox align="center" className="right-links">
          <ul className="icons d-flex">
            {!hideSocialIcons && (
              <>
                <li>
                  <a
                    className="footer-link footer-icon"
                    target="_blank"
                    href={footerData.instagramLink}
                    rel="noreferrer"
                    aria-label="Instagram"
                  >
                    <InstagramIcon />
                  </a>
                </li>
                <li>
                  <a
                    className="footer-link footer-icon"
                    target="_blank"
                    href={footerData.youtubeLink}
                    rel="noreferrer"
                    aria-label="Youtube"
                  >
                    <YoutubeIcon />
                  </a>
                </li>
                <li>
                  <a
                    className="footer-link footer-icon"
                    target="_blank"
                    href={footerData.twitterLink}
                    rel="noreferrer"
                    aria-label="Twitter"
                  >
                    <TwitterIcon />
                  </a>
                </li>
              </>
            )}
            <li className="app-linkWrapper">
              <Link className="footer-link app-link" to={footerData.getTheAppLink}>
                Get the App
              </Link>
            </li>
          </ul>
        </FlexBox>
      </div>
      <div id="footer-mobile" className="footer mobile">
        <div className="top-links topLinks">
          <ul className="topLinks__list">
            {footerData.links.map((link, i) => (
              <li key={i} className="topLinks__item">
                <Link className="footer-link topLinks__link" to={link.url}>
                  <span>{link.title}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="bottom-links">
          <ul className="icons d-flex">
            <li>
              <a
                className="footer-link footer-icon"
                target="_blank"
                href={footerData.instagramLink}
                rel="noreferrer"
                aria-label="Instagram"
              >
                <InstagramIcon />
              </a>
            </li>
            <li>
              <a
                className="footer-link footer-icon"
                target="_blank"
                href={footerData.youtubeLink}
                rel="noreferrer"
                aria-label="Youtube"
              >
                <YoutubeIcon />
              </a>
            </li>
            <li>
              <a
                className="footer-link footer-icon"
                target="_blank"
                href={footerData.twitterLink}
                rel="noreferrer"
                aria-label="Twitter"
              >
                <TwitterIcon />
              </a>
            </li>
            <li className="app-linkWrapper">
              <Link className="footer-link app-link" to={footerData.getTheAppLink}>
                Get the App
              </Link>
            </li>
          </ul>
        </div>
        <div className="requirement-links">
          <ul className="d-flex">
            {footerData.caRequirementLinks.map(({ url, title, cssClass }, i) => {
              if (title?.toLowerCase().includes('consent')) {
                return (
                  <li key={i}>
                    <a
                      id="termly-consent-preferences"
                      className="footer-link"
                      onClick={() => {
                        window?.displayPreferenceModal();
                        return false;
                      }}
                    >
                      <span className="text--subdued">{title}</span>
                    </a>
                  </li>
                )
              }

              return (
                <li key={i}>
                  <a
                    className="footer-link"
                    href={url}
                    target='_blank'
                  >
                    <span className="text--subdued">{title}</span>
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
