import React, { useContext, Fragment } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import StoreContext from '~/layouts/StoreContext';
import Drawer from '@material-ui/core/Drawer';
import { BodyText, BodyTextAlt, Heading } from '~/components/.base/headings';
import { RedButton, WhiteButton } from '~/components/.base/buttons';
import LineItem from './LineItem.js';
import { FlexBox } from '~/components/.base/containers';
import './SidebarCart.scss';
import payment_icons from '~/images/cart/payment_icons.png';
import TagIcon from '~/images/icons/tag.svg';

import InfoIcon from '~/images/icons/pdp/info.svg';
import Tooltip from '~/components/.base/tooltip';

const Payments = styled.div`
  margin-top: 15px;
  text-align: center;
  img {
    width: 180px;
    max-width: 100%;
  }
`;

const SidebarCart = () => {
  const {
    store: {
      checkout,
      checkout: { lineItems, discountApplications },
      sidebarCartOpen,
      trueLinePrices,
      trueLineItemsSubtotal,
      trueLineItemsSubtotalNoDiscounts,
      trueLineItemsSubtotalNoDiscountsNoApparel,
      trueLineItemsBasePriceSubtotalNoDiscounts,
    },
    closeCart,
  } = useContext(StoreContext);
  return (
    <Drawer
      aria-label="Sidebar cart"
      role="dialog"
      aria-modal="true"
      anchor="right"
      open={sidebarCartOpen}
      onClose={closeCart}
      className={'sidebar-cart relative'}
    >
      <FlexBox className="cart-inner" direction="column" align="center" justify="space-between">
        <div className="cart-top">
          <div className="header">
            <Heading>Cart</Heading>
            <button className="buttonIcon close-icon" onClick={closeCart} aria-label="Close">
              &#10005;
            </button>
          </div>
          <div className="shipping-bar">
            {trueLinePrices.length && trueLineItemsSubtotalNoDiscountsNoApparel >= 250 ? (
              <Fragment>
                <BodyText>You have earned free shipping!</BodyText>
                <div className="progress-bar">
                  <div
                    className="progress"
                    style={{
                      width: '100%',
                    }}
                    role="progressbar"
                    aria-valuenow="250"
                    aria-valuemin="0"
                    aria-valuemax="250"
                    aria-busy={false}
                  />
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <BodyText>
                  Spend $
                  {(
                    250 -
                    (trueLineItemsSubtotalNoDiscountsNoApparel
                      ? trueLineItemsSubtotalNoDiscountsNoApparel
                      : 0)
                  ).toFixed(2)}{' '}
                  more and get free shipping!
                </BodyText>
                <div className="progress-bar">
                  <div
                    className="progress"
                    style={{
                      width: `${
                        (trueLineItemsSubtotalNoDiscountsNoApparel
                          ? trueLineItemsSubtotalNoDiscountsNoApparel
                          : 0) / 2.5
                      }%`,
                    }}
                    role="progressbar"
                    aria-valuenow={
                      trueLineItemsSubtotalNoDiscounts
                        ? trueLineItemsSubtotalNoDiscounts <= 250
                          ? trueLineItemsSubtotalNoDiscounts
                          : 250
                        : 0
                    }
                    aria-valuemin="0"
                    aria-valuemax="250"
                    aria-busy={false}
                  />
                </div>
              </Fragment>
            )}
          </div>
          <div className="line-items-container">
            {lineItems.length ? (
              lineItems.map((lineItem, i) => {
                return <LineItem key={lineItem.id.toString()} lineItem={lineItem} index={i} />;
              })
            ) : (
              <div className="no-items">
                <p>You have no items in your cart.</p>
                <WhiteButton onClick={closeCart}>Continue Shopping</WhiteButton>
              </div>
            )}
          </div>
        </div>
        <div className="cart-bottom">
          <div className="totals-cont">
            <div className="cart-shipping-info">
              <BodyTextAlt className="heading">
                Shipping <span className="desktop">information</span>
                <span className="mobile">info</span>
              </BodyTextAlt>
              <Tooltip
                title={
                  <>
                    <BodyTextAlt color="#fff" className="heading apparel-heading" md="10px 0 5px">
                      Food Shipping
                    </BodyTextAlt>
                    <BodyText md="0 0 2px" color="#fff">
                      $35 Flat Rate for orders under $150
                    </BodyText>
                    <BodyText md="0 0 2px" color="#fff">
                      $19.99 for orders $150 - $250
                    </BodyText>
                    <BodyText md="0 0 2px" color="#fff">
                      Free Shipping for orders over $250
                    </BodyText>
                    <BodyTextAlt color="#fff" className="heading apparel-heading" md="7px 0 5px">
                      Apparel Shipping
                    </BodyTextAlt>
                    <BodyText md="0 0 10px" color="#fff">
                      Separate $9.99 Flat Rate
                    </BodyText>
                  </>
                }
                placement="bottom-end"
              >
                <InfoIcon />
              </Tooltip>
            </div>
            <div className="totals">
              <div className="total-item">
                <BodyTextAlt>Items:</BodyTextAlt>
                <BodyTextAlt>${trueLineItemsBasePriceSubtotalNoDiscounts.toFixed(2)}</BodyTextAlt>
              </div>
              {trueLineItemsBasePriceSubtotalNoDiscounts > trueLineItemsSubtotalNoDiscounts ? (
                <div className="total-item savings">
                  <BodyTextAlt color="#d4212c">Savings:</BodyTextAlt>
                  <BodyTextAlt color="#d4212c">
                    $
                    {(
                      trueLineItemsBasePriceSubtotalNoDiscounts - trueLineItemsSubtotalNoDiscounts
                    ).toFixed(2)}
                  </BodyTextAlt>
                </div>
              ) : null}
              {trueLineItemsSubtotalNoDiscounts > trueLineItemsSubtotal ? (
                <div className="total-item savings">
                  <TagIcon
                    style={{
                      width: '17px',
                      height: '17px',
                      marginRight: '5px',
                      fill: '#555',
                    }}
                  />{' '}
                  <BodyTextAlt color="#d4212c">{discountApplications?.[0]?.code}:</BodyTextAlt>
                  <BodyTextAlt color="#d4212c">
                    ${(trueLineItemsSubtotalNoDiscounts - trueLineItemsSubtotal).toFixed(2)}
                  </BodyTextAlt>
                </div>
              ) : null}
              <div className="total-item">
                <BodyTextAlt>Subtotal:</BodyTextAlt>
                <BodyTextAlt>${trueLineItemsSubtotal.toFixed(2)}</BodyTextAlt>
              </div>
            </div>
          </div>
          <Payments>
            <BodyText m="0 0 10px">Taxes calculated at checkout</BodyText>
            <img
              src={payment_icons}
              alt="Supported Payment Methods: Visa, MasterCard, Discover, and American Express"
            />
          </Payments>
          <div className="total-item checkout-btn">
            {lineItems.length ? (
              <RedButton as={Link} to="/cart" onClick={closeCart}>
                Checkout
              </RedButton>
            ) : (
              <RedButton onClick={closeCart} aria-label="Close">
                Close
              </RedButton>
            )}
          </div>
        </div>
      </FlexBox>
    </Drawer>
  );
};
export default SidebarCart;
