/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  const queryParams = new URLSearchParams(location.search);
  if (queryParams.has('variant')) {
    return false;
  }
  const queriedPosition = getSavedScrollPosition({ pathname: location.pathname });
  window.scrollTo(...(queriedPosition || [0, 0]));
  return false;
};
