import React, { useState } from 'react';
import styled from 'styled-components';
export const SearchForm = styled.div`
    position: relative;
    width: ${props => props.w};
    margin: ${props => props.md};
    input,
    button {
        border-radius: 25px;
        height: 50px;
    }
    input {
        padding: 20px 20px 8px;
        border: 1px solid #e2e4e4;
        width: 100%;
        outline: none;
        background: transparent;
        transition: all 0.2s linear;
    }
    @media screen and (min-width: 768px) {
        &:hover {
            input {
                // border: 1px solid #5c6566;
                &::placeholder {
                    // color: #5c6566;
                }
            }
        }
    }
    input {
        &:focus {
            border-color: #d4212c;
        }
    }

    label,
    .error {
        display: block;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #5c6566;
        font-family: Montserrat, sans-serif;
        height: auto;
    }
    .error {
        color: #d4212c;
        text-align: right;
        margin-right: 2.5rem;
        margin-top: 0.25rem;
    }
    button {
        background-color: #d4212c;
        color: #fff;
        outline: none !important;
        border: 1px solid #d4212c;
        transition: all 0.2s linear;
        text-transform: uppercase;
        font-family: Montserrat, sans-serif;
        font-weight: 500;
        font-size: 12px;
        min-width: 50px;
        position: absolute;
        right: 0;
        letter-spacing: 1px;
        @media screen and (min-width: 768px) {
            &:hover,
            &:focus {
                color: #d4212c;
                background-color: #fff;
                svg g {
                    stroke: #d4212c;
                    fill: #d4212c;
                    color: #d4212c;
                }
            }
        }
        svg {
            overflow: visible;
            g {
                stroke: #fff;
                fill: #fff;
                color: #fff;
            }
        }
        &.no-stroke {
            svg g {
                stroke: none;
            }
        }
    }
`;
export const SearchFormLarge = styled.div`
    position: relative;
    width: ${props => props.w};
    margin: ${props => props.md};
    > * {
        height: 60px;
    }
    label {
        position: absolute;
        font-size: 12px;
        height: fit-content;
    }
    input {
        padding: 16px 40px 8px 0px;
        border: solid 1px #c4cbcc;
        border-width: 0 0 1px;
        width: 100%;
        outline: none;
        font-size: 24px;
        transition: all 0.2s linear;
    }
    input[type='number'] {
        -moz-appearance: textfield;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    @media screen and (min-width: 768px) {
        &:hover {
            input {
                border-color: #5c6566;
                &::placeholder {
                    color: #5c6566;
                }
            }
        }
    }
    input {
        &:focus {
            border-color: #d4212c;
        }
    }
    button {
        border-radius: 30px;
        background-color: #d4212c;
        color: #fff;
        outline: none !important;
        border: 1px solid #d4212c;
        transition: all 0.2s linear;
        text-transform: uppercase;
        font-family: Montserrat, sans-serif;
        font-weight: 500;
        font-size: 12px;
        min-width: 60px;
        position: absolute;
        letter-spacing: 1px;
        right: 0;
        top: -8px;
        &:hover {
            color: #d4212c;
            background-color: #fff;
            svg g {
                stroke: #d4212c;
                fill: #d4212c;
                color: #d4212c;
            }
        }
        svg {
            overflow: visible;
            g {
                stroke: #fff;
                fill: #fff;
                color: #fff;
            }
        }
        &.no-stroke {
            svg g {
                stroke: none;
            }
        }
    }
`;

export const CheckoutInput = styled.div`
    font-family: Montserrat, sans-serif;
    margin-bottom: 1.5rem;
    margin: ${props => props.md};
    flex-grow: 1;
    position: relative;
    label,
    .label,
    .error {
        display: block;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #5c6566;
        margin-bottom: 0.25rem;
    }
    .error {
        color: #d4212c;
        text-align: right;
        margin-right: 2.5rem;
        margin-top: 0.25rem;
    }
    .aux-message {
        position: absolute;
        right: 2.5rem;
        top: 0;
        margin-bottom: 0.5rem;
        .link {
            cursor: pointer;
            text-decoration: underline;
        }
    }
    input {
        outline: none;
        border-radius: 25px;
        border: 1px solid #e2e4e4;
        width: calc(100% - 10px);
        width: ${props => props.w};
        height: 40px;
        height: ${props => props.h};
        padding: 10px 25px;
        color: #5c6566;
        font-family: TimesNewRomanPSMT;
        box-sizing: border-box;
        transition: all 0.2s linear;
        &::placeholder {
            color: #5c6566;
        }
    }
    @media screen and (min-width: 768px) {
        &:hover {
            input {
                border: 1px solid #5c6566;
            }
        }
    }
    input {
        &.is-invalid,
        &:focus {
            border: 1px solid #d4212c;
        }
    }
`;

export const CheckoutSelect = styled.div`
    font-family: Montserrat, sans-serif;
    margin-bottom: 1.5rem;
    position: relative;
    label {
        display: block;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #5c6566;
    }
    &:hover {
        select {
            border-color: #5c6566;
        }
    }
    select {
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        &::-ms-expand {
            display: none;
        }
        font-family: Montserrat, sans-serif;
        font-size: 12px;
        border-radius: 25px;
        border: 1px solid #e2e4e4;
        height: 40px;
        width: calc(100% - 10px);
        width: ${props => props.w};
        padding: 0px 25px;
        color: #5c6566;
        background-color: #fff;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1px;
        option {
            text-transform: none;
        }
        &:focus {
            border-color: #d4212c;
        }
    }
    svg {
        position: absolute;
        width: 16px;
        height: 16px;
        right: 25px;
        right: ${props => props.svgRight};
        top: 35px;
        top: ${props => props.svgTop};
        g {
            fill: #d4212c;
        }
    }
`;

export const InputSelect = styled.div`
    font-family: Montserrat, sans-serif;
    margin-top: ${props => props.mt};
    margin-bottom: 1.5rem;
    margin-bottom: ${props => props.mb};
    position: relative;
    label,
    .label {
        display: block;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #5c6566;
    }
    .label {
        margin-bottom: 0.25rem;
    }
    &:hover {
        select {
            border-color: #5c6566;
        }
    }
    select {
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        &::-ms-expand {
            display: none;
        }
        font-family: Montserrat, sans-serif;
        font-size: 12px;
        letter-spacing: 1px;
        border-radius: 25px;
        border: 1px solid #e2e4e4;
        height: 50px;
        width: ${props => props.w};
        padding: 10px 35px 10px 20px;
        color: #5c6566;
        background-color: #fff;
        text-transform: uppercase;
        option {
            text-transform: none;
        }
        &:focus {
            border-color: #d4212c;
        }
    }
    svg {
        position: absolute;
        width: 16px;
        height: 16px;
        right: 15px;
        right: ${props => props.svgRight};
        top: 41.5px;
        top: ${props => props.svgTop};
        fill: #d4212c;
        g {
            fill: #d4212c;
        }
    }
`;
// top: 51.5px;
export const FormControl = styled.div`
    font-family: Montserrat, sans-serif;
    margin-bottom: 1.5rem;
    margin: ${props => props.md};
    flex-grow: 1;
    position: relative;
    label,
    .label,
    .error {
        display: block;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #5c6566;
        .red {
            color: #d4212c;
        }
    }
    .error {
        color: #d4212c;
        text-align: right;
        margin-right: 2.5rem;
        margin-top: 0.25rem;
    }
    .aux-message {
        position: absolute;
        right: 2.5rem;
        top: 0;
        margin-bottom: 0.5rem;
        .link {
            cursor: pointer;
            text-decoration: underline;
        }
    }
    input {
        font-family: TimesNewRomanPSMT;
        outline: none;
        border-radius: 30px;
        border: 1px solid #e2e4e4;
        width: calc(100% - 2.5rem);
        width: ${props => props.w};
        height: 60px;
        height: ${props => props.h};
        padding: 10px 25px;
        color: #5c6566;
        box-sizing: border-box;
        transition: all 0.2s linear;
        &::placeholder {
            color: #5c6566;
        }
    }
    &.date-picker {
        position: relative;
        font-family: Montserrat, sans-serif;
        text-transform: uppercase;
        .MuiInputBase-root {
            padding: 0;
            width: 100%;
        }
        .MuiFormControl-root {
            width: 100%;
            font-family: inherit;
            input {
                width: 100%;
                background-color: #fff;
                border: none;
                font-size: 12px;
                line-height: 18px;
                color: #5c6566;
                &:focus {
                    color: #2e3233;
                }
                &:hover {
                    color: #5c6566;
                }
            }
            .MuiInput-underline:before {
                content: none;
            }
            .MuiInput-underline:after {
                content: none;
            }
            .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
                position: absolute;
                right: 25px;
                * {
                    outline: none;
                }
            }
            .MuiFormHelperText-root {
                color: #d4212c;
                font-family: Montserrat, sans-serif;
                text-align: right;
                text-transform: uppercase;
                margin-right: 2.5rem;
                margin-top: 0.25rem;
            }
            fieldset {
                height: 60px;
                top: 0;
                border-radius: 30px;
                legend {
                    display: none;
                }
            }
        }
        .MuiInputLabel-root {
            position: relative;
            max-width: 100%;
            transform: none;
        }
        .MuiSvgIcon-root {
            fill: #d4212c;
        }
        &.is-invalid {
            input {
                border-color: #d4212c;
            }
        }
    }

    @media screen and (min-width: 768px) {
        &:hover {
            input {
                border: 1px solid #5c6566;
            }
        }
    }
    input {
        &.is-invalid,
        &:focus {
            border: 1px solid #d4212c;
        }
    }
    &.date-picker {
        width: calc(100% - 2.5rem);
        width: ${props => props.w};
        input {
            width: 100%;
            font-family: Montserrat, sans-serif;
            text-transform: uppercase;
        }
    }
    textarea {
        font-family: TimesNewRomanPSMT;
        outline: none;
        border-radius: 25px;
        border: 1px solid #e2e4e4;
        width: calc(100% - 2.5rem);
        width: ${props => props.w};
        resize: none;
        padding: 10px 25px;
        color: #5c6566;
        box-sizing: border-box;
        &::placeholder {
            color: #5c6566;
        }
        &.is-invalid {
            border: 1px solid #d4212c;
        }
    }
`;
export const FormControlSelect = styled.div`
    font-family: Montserrat, sans-serif;
    margin-top: ${props => props.mt};
    margin-bottom: 1.5rem;
    position: relative;
    label,
    .label,
    .error {
        display: block;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #5c6566;
        .red {
            color: #d4212c;
        }
    }
    .error {
        color: #d4212c;
        text-align: right;
        margin-right: 2.5rem;
        margin-top: 0.25rem;
    }
    &:hover {
        select {
            border-color: #5c6566;
        }
    }
    select {
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        &::-ms-expand {
            display: none;
        }
        &.is-invalid {
            border: 1px solid #d4212c;
        }
        font-family: Montserrat, sans-serif;
        font-size: 12px;
        border-radius: 30px;
        border: 1px solid #e2e4e4;
        height: 60px;
        width: ${props => props.w};
        padding: 10px 35px 10px 20px;
        color: #5c6566;
        background-color: #fff;
        text-transform: uppercase;
        option {
            text-transform: none;
        }
        &:focus {
            border-color: #d4212c;
        }
    }
    svg {
        position: absolute;
        width: 16px;
        height: 16px;
        right: calc(2rem + 15px);
        right: ${props => props.svgRight};
        top: 51.5px;
        top: ${props => props.svgTop};
        fill: #d4212c;
        g {
            fill: #d4212c;
        }
    }
`;

export const FormSuccess = styled.div`
    margin: 0 2rem;
    p {
        text-align: center;
        color: #d4212c;
        margin-bottom: 2rem;
    }
`;

export const FormError = styled.div`
    margin: 0 2rem;
    p {
        text-align: center;
        color: #d4212c;
        margin-bottom: 2rem;
    }
`;

export const CheckboxInput = styled.label`
    /* Customize the label (the container) */

    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-top: -0.5rem;
    margin-bottom: 0.75rem;
    /* Hide the browser's default checkbox */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
`;

const FormGroup = styled.div`
    position: relative;
    display: inline-block;
    width: 100%;
    input {
        border-radius: 25px;
        height: 50px;
        &::placeholder {
            color: #5c6566;
        }
    }
    label {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0.9;
        pointer-events: none;
        transition: all 0.2s ease-in-out;
        color: #2e3233;
    }
    &.is-floating label {
        top: 4px;
        transform: none;
        font-size: 11px;
        opacity: 1;
    }

    &.has-focus label {
        opacity: 1 !important;
    }
`;

export const TextField = ({ label, htmlFor, ...attrs }) => {
    const [hasFocus, setHasFocus] = useState(false);
    const [isFloating, setIsFloating] = useState(false);

    const handleInputFocus = () => {
        setHasFocus(true);
        setIsFloating(true);
    };

    const handleInputBlur = e => {
        setHasFocus(false);
        if (e.target.value === '') {
            setIsFloating(false);
        }
    };
    return (
        <FormGroup className={`${hasFocus ? 'has-focus' : ''} ${isFloating ? 'is-floating' : ''}`}>
            <label htmlFor={htmlFor}>{label}</label>
            <input id={htmlFor} onFocus={handleInputFocus} onBlur={handleInputBlur} {...attrs} />
        </FormGroup>
    );
};
