import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import ContextProvider from './ContextProvider';
import { MainMenu, EcommerceMenu, Footer, FooterEcommerce } from '~/components/Navigation';
import '../utils/vendor.min.css';
import '../utils/styles.css';
import LoadingBar from '../components/Navigation/LoadingBar';
import { breakpoint } from '../constants/Breakpoint';
import { StackedLogo } from '../components/Navigation/Logos';

import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#d4212c',
    },
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#d4212c',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#d4212c',
        },
      },
    },
  },
});

const Main = styled.main`
  .main-navigation:not(.white-header) + & {
    padding-top: 64px;
    @media ${breakpoint.lg} {
      padding-top: 90px;
    }
  }
  .ecomm-navigation:not(.white-header) + & {
    padding-top: 64px;
    @media ${breakpoint.lg} {
      padding-top: 123px;
    }
  }
  #navigation:not(.white-header) + & {
    @supports (--css: variables) {
      padding-top: var(--header-height);
    }
  }
  #navigation.home-page + & {
    padding-top: 0;
  }
`;

const LogoHeader = styled.header`
  @media screen and ${breakpoint.md} {
    padding: 30px;
  }
  @media screen and ${breakpoint.maxSm} {
    padding: 15px;
  }
`;

const Layout = ({ children, location }) => {
  console.log('layout');
  const [checkoutHeader, setCheckoutHeader] = useState(false);
  const [cartHeader, setCartHeader] = useState(false);
  const [hideSocialIcons, setHideSocialIcons] = useState(false);
  const [headerType, setHeaderType] = useState(null);
  const [isHomePage, setIsHomePage] = useState(true);
  const [isShopPage, setIsShopPage] = useState(false);
  const [isPDPOrCheckout, setIsPDPOrCheckout] = useState(false);
  const [asyncScriptLoad, setAsyncScriptLoad] = useState(false);
  useEffect(() => {
    setCheckoutHeader(
      location.pathname.includes('checkout') || location.pathname.includes('order-completed')
    );
    setCartHeader(location.pathname.includes('cart'));
    setHideSocialIcons(location.pathname.includes('/locations/details'));
    if (location.pathname.includes('no-tickets-needed')) {
      setHeaderType('logo');
    } else if (
      location.pathname.includes('collections') ||
      location.pathname.includes('shop') ||
      location.pathname.includes('collection-list') ||
      location.pathname.includes('checkout') ||
      location.pathname.includes('cart') ||
      location.pathname.includes('products') ||
      location.pathname.includes('account') ||
      location.pathname.includes('order-completed') ||
      location.pathname.includes('create-order')
    ) {
      setHeaderType('ecommerce');
    } else {
      setHeaderType('regular');
    }
    setIsPDPOrCheckout(
      location.pathname.includes('products') ||
        location.pathname.includes('cart') ||
        location.pathname.includes('checkout') ||
        location.pathname.includes('account') ||
        location.pathname.includes('order-completed') ||
        location.pathname.includes('create-order')
    );
    setIsHomePage(location.pathname === '/');
    setIsShopPage(location.pathname === '/shop' || location.pathname === '/shop/');
  }, [location]);

  useEffect(() => {
    if (document) {
      // ADA ACCESSIBE SCRIPT
      const initACSB = () => {
        // console.log("ACCESSIBE");
        // var s = document.createElement("script"),
        //   e = !document.body ? document.querySelector("head") : document.body;
        // s.src = "https://acsbapp.com/apps/app/dist/js/app.js";
        // s.async = true;
        // s.onload = function() {
        //   if (window.acsbJS)
        //     window.acsbJS.init({
        //       statementLink: "",
        //       footerHtml: "",
        //       hideMobile: false,
        //       hideTrigger: false,
        //       language: "en",
        //       position: "left",
        //       leadColor: "#f81616",
        //       triggerColor: "#f81616",
        //       triggerRadius: "50%",
        //       triggerPositionX: "left",
        //       triggerPositionY: "bottom",
        //       triggerIcon: "help",
        //       triggerSize: "medium",
        //       triggerOffsetX: 20,
        //       triggerOffsetY: 50,
        //       mobile: {
        //         triggerSize: "small",
        //         triggerPositionX: "left",
        //         triggerPositionY: "center",
        //         triggerOffsetX: 0,
        //         triggerOffsetY: 0,
        //         triggerRadius: "0",
        //       },
        //     });
        // };
        // e.appendChild(s);
      };
      const initKlaviyo = () => {
        console.log('klaviyo');
        var s = document.createElement('script'),
          e = !document.body ? document.querySelector('head') : document.body;
        s.src = 'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=WcPrXs';
        s.async = true;
        s.onload = () => console.log('klaviyo loaded');
        e.appendChild(s);
      };
      const initCriteo = () => {
        console.log('criteo');
        var s = document.createElement('script'),
          e = !document.body ? document.querySelector('head') : document.body;
        s.src = 'https://dynamic.criteo.com/js/ld/ld.js?a=83023';
        s.async = true;
        s.onload = () => {
          console.log('criteo loaded');
          setAsyncScriptLoad(true);
        };
        e.appendChild(s);
      };
      const timeoutFn = () => {
        if (window.requestIdleCallback) {
          setTimeout(window.requestIdleCallback(initACSB), 1000);
          setTimeout(window.requestIdleCallback(initKlaviyo), 1000);
        } else {
          setTimeout(initACSB, 1000);
          setTimeout(initKlaviyo, 1000);
        }
        window.removeEventListener('scroll', timeoutFn);
      };
      initCriteo();
      window.addEventListener('scroll', timeoutFn);
    }
  }, []);
  return (
    <ContextProvider asyncScriptLoad={asyncScriptLoad}>
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                title
              }
            }
            logoData: allFile(
              filter: {
                extension: { regex: "/(jpg)|(png)/" }
                absolutePath: { regex: "/images/logo/" }
              }
            ) {
              edges {
                node {
                  name
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 100) {
                      aspectRatio
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
            }
            headerData: contentfulNavigation(title: { eq: "Main Site Header" }) {
              title
              links {
                title
                url
                showLink
                subLinks {
                  title
                  url
                  showLink
                }
                useMegaMenu
                megaMenu {
                  title
                  subLinks {
                    title
                    url
                    showLink
                  }
                  toutOneImage {
                    fluid(maxWidth: 800, quality: 100) {
                      aspectRatio
                      ...GatsbyContentfulFluid_withWebp
                    }
                  }
                  toutOneText
                  toutOneLink
                  toutTwoImage {
                    fluid(maxWidth: 800, quality: 100) {
                      aspectRatio
                      ...GatsbyContentfulFluid_withWebp
                    }
                  }
                  toutTwoText
                  toutTwoLink
                  toutThreeImage {
                    fluid(maxWidth: 800, quality: 100) {
                      aspectRatio
                      ...GatsbyContentfulFluid_withWebp
                    }
                  }
                  toutThreeText
                  toutThreeLink
                }
              }
              buttonText
              buttonLabel
              buttonLink
              announcementBannerIsVisible
              announcementBannerLink
              announcementBannerBgColor
              announcementBannerContent {
                raw
              }
            }
            ecommHeaderData: contentfulNavigation(title: { eq: "Ecommerce Header" }) {
              title
              links {
                title
                url
                showLink
                subLinks {
                  title
                  url
                  showLink
                }
                useMegaMenu
                megaMenu {
                  title
                  subLinks {
                    title
                    url
                    showLink
                  }
                  toutOneImage {
                    fluid(maxWidth: 800, quality: 100) {
                      aspectRatio
                      ...GatsbyContentfulFluid_withWebp
                    }
                  }
                  toutOneText
                  toutOneLink
                  toutTwoImage {
                    fluid(maxWidth: 800, quality: 100) {
                      aspectRatio
                      ...GatsbyContentfulFluid_withWebp
                    }
                  }
                  toutTwoText
                  toutTwoLink
                  toutThreeImage {
                    fluid(maxWidth: 800, quality: 100) {
                      aspectRatio
                      ...GatsbyContentfulFluid_withWebp
                    }
                  }
                  toutThreeText
                  toutThreeLink
                }
              }
            }
            policityData: contentfulNavigation(title: { eq: "Policy" }) {
              title
              links {
                title
                url
                showLink
              }
            }
            footerData: contentfulFooter(title: { eq: "Footer" }) {
              emailAddress
              phoneNumber
              links {
                cssClass
                title
                url
                showLink
              }
              caRequirementLinks {
                cssClass
                title
                url
                showLink
              }
              instagramLink
              twitterLink
              youtubeLink
              getTheAppLink
            }
            footerImages: allFile(
              filter: {
                extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
                absolutePath: { regex: "/images/footer/" }
              }
            ) {
              edges {
                node {
                  name
                  childImageSharp {
                    fluid(maxWidth: 915, quality: 100) {
                      aspectRatio
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          return (
            <ThemeProvider theme={theme}>
              <a
                href="#main"
                className="skip-link"
                style={{ position: 'absolute', top: '-40px', left: '0' }}
              >
                Skip to main content
              </a>
              <LoadingBar />
              <noscript>
                <div id="noscript-nav">
                  {data.headerData.links.map((link, i) => {
                    if (!link.showLink) return null;
                    return (
                      <div key={i}>
                        <div className={`noscript-header-link`}>
                          <Link to={link.url}>
                            <span>{link.title}</span>
                          </Link>
                          {link.subLinks && !link.useMegaMenu ? (
                            <div className="sublinks">
                              {link.subLinks.map((subLink, i) => (
                                <Link key={i} to={subLink.url}>
                                  <div className={`sublink`}>
                                    <span>{subLink.title}</span>
                                  </div>
                                </Link>
                              ))}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                  {data.ecommHeaderData.links.map((link, i) => {
                    if (!link.showLink) return null;
                    return (
                      <div key={i}>
                        <div className={`noscript-header-link`}>
                          <Link to={link.url}>
                            <span>{link.title}</span>
                          </Link>
                          {link.subLinks && !link.useMegaMenu ? (
                            <div className="sublinks">
                              {link.subLinks.map((subLink, i) => (
                                <Link key={i} to={subLink.url}>
                                  <div className={`sublink`}>
                                    <span>{subLink.title}</span>
                                  </div>
                                </Link>
                              ))}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </noscript>
              {headerType === 'logo' && (
                <LogoHeader>
                  <Link to="/">
                    <StackedLogo w="140px" />
                  </Link>
                </LogoHeader>
              )}
              {headerType === 'ecommerce' ? (
                <>
                  <EcommerceMenu
                    logoData={data.logoData.edges}
                    isShopPage={isShopPage}
                    headerLinks={data.ecommHeaderData}
                    policyLinks={data.policityData}
                    siteTitle={data.site.siteMetadata.title}
                    checkoutHeader={checkoutHeader}
                    isPDP={isPDPOrCheckout}
                  />
                </>
              ) : null}
              {headerType === 'regular' ? (
                <>
                  <MainMenu
                    logoData={data.logoData.edges}
                    isHomePage={isHomePage}
                    headerLinks={data.headerData}
                    policyLinks={data.policityData}
                    siteTitle={data.site.siteMetadata.title}
                    checkoutHeader={checkoutHeader}
                    isSearchPage={location.pathname.includes('location/search')}
                  />
                </>
              ) : null}
              <Main id="main" tabIndex="-1">
                {children}
              </Main>
              {headerType === 'regular' ? (
                <Footer
                  footerImages={data.footerImages.edges}
                  hideSocialIcons={hideSocialIcons}
                  footerData={data.footerData}
                />
              ) : null}
              {headerType === 'ecommerce' ? (
                checkoutHeader ? null : (
                  <FooterEcommerce
                    cartHeader={cartHeader}
                    footerImages={data.footerImages.edges}
                    footerData={data.footerData}
                    policyLinks={data.policityData}
                  />
                )
              ) : null}
            </ThemeProvider>
          );
        }}
      />
    </ContextProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
