import axios from 'axios';
let serverUrl = '';
// serverUrl = "http://localhost:3000";
serverUrl = `${process.env.GATSBY_SERVER_API_URL}`;

export const verifyDiscount = (discountCode, productHandle, collectionHandle) => {
    return axios.post(`${serverUrl}/api/discounts`, {
        discountCode,
        productHandle,
        collectionHandle,
    });
};

export const productHasDiscount = (discountCode, productHandle) => {
    return axios.post(`${serverUrl}/api/discounts/product_handle`, {
        discountCode,
        productHandle,
    });
};

export const verifyDiscountCode = (discountCode, collectionHandle) => {
    return axios.post(`${serverUrl}/api/discounts/collection_handle`, {
        discountCode,
        collectionHandle,
    });
};

export const checkDiscount = discountCode => {
    return axios.post(`${serverUrl}/api/discounts/code`, {
        discountCode,
    });
};
