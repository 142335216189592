import styled from 'styled-components';

import { breakpoint } from '../../constants/Breakpoint';

export const Container = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  @media ${breakpoint.lg} {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media ${breakpoint.xxl} {
    max-width: 1366px;
  }
`;

export const ImageContainer = styled.div`
  img {
    width: 100%;
    height: 100%;
  }
`;

export const Grid = styled.div``;

export const Row = styled.div`
  display: flex;
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
  justify-content: ${props => props.justify};
  align-items: ${props => props.align};
`;

export const Col = styled.div`
  flex: ${props => props.size};
  padding: ${props => props.pd};
  height: ${props => props.h};
  max-width: ${props => props.maxWidth || '100%'};
`;

export const CenteredContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FlexBox = styled.div`
  display: flex;
  width: ${props => props.w};
  height: ${props => props.h};
  flex-direction: ${props => props.direction};
  justify-content: ${props => props.justify};
  align-items: ${props => props.align};
  flex-wrap: ${props => props.wrap};
`;

export const RadioGroup = styled.div`
  border-radius: 30px;
  border: 1px solid #e2e4e4;
  .radio-item {
    padding: 20px 0px;
    margin: 0 25px;
    border-bottom: 1px solid #e2e4e4;
    cursor: pointer;
    h3.centered {
      margin: 0;
    }
    .text {
      display: flex;
      align-items: center;
    }
    .bullet-point {
      width: 20px;
      min-width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #d4212c;
      padding: 2px;
      margin-right: 1rem;
      .inner {
        border-radius: 50%;
        background-color: #d4212c;
        width: 100%;
        height: 100%;
      }
    }
    .input-radio {
      outline: none;
      appearance: none;
      width: 20px;
      min-width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #d4212c;
      padding: 2px;
      margin-right: 1rem;
      box-shadow: inset 0 0 0 10px #ffffff;
      transition: 0.2s box-shadow ease-in-out;
      &:focus {
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4), 0 0 0 3px rgba(212, 33, 44, 0.75);
      }
      &:checked {
        box-shadow: inset 0 0 0 2px #ffffff, inset 0 0 0 10px #d5212c;
        &:focus {
          box-shadow: inset 0 0 0 2px #ffffff, inset 0 0 0 10px #d5212c,
            0 0 0 3px rgba(212, 33, 44, 0.75);
        }
      }
    }
    &:last-child {
      border: none;
    }
  }
`;

export const BorderlessRadioGroup = styled.div`
  .radio-item {
    cursor: pointer;
    display: flex;
    h3.centered {
      margin: 0;
    }
    .text {
      display: flex;
      align-items: center;
    }
    .bullet-point {
      width: 20px;
      min-width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #d4212c;
      padding: 2px;
      margin-right: 5px;
      .inner {
        border-radius: 50%;
        background-color: #d4212c;
        width: 100%;
        height: 100%;
      }
    }
    &:last-child {
      margin-left: 20px;
    }
  }
`;

export const PageContainer = styled.div`
  // padding-top: 92px;
  /* @media screen and (max-width: 950px) {
		padding-top: 63px;
	} */
`;

export const PageContainerEcomm = styled.div`
  /* @media screen and (max-width: 950px) {
		padding-top: 64px;
	} */
`;

export const BackgroundDivBgImage = styled.div`
  position: absolute;
  z-index: -1;
  width: ${props => props.w};
  height: ${props => props.h};
  top: ${props => props.top};
  left: ${props => props.left};
  right: ${props => props.right};
  bottom: ${props => props.bottom};
  background-image: ${props => (props.image ? `url(${props.image})` : null)};
  background-color: ${props => props.color};
  @media screen and (max-width: ${props => (props.tablet ? '950px' : '767px')}) {
    top: ${props => props.topMobile};
    left: ${props => props.leftMobile};
    right: ${props => props.rightMobile};
    bottom: ${props => props.bottomMobile};
    height: ${props => props.heightMobile};
  }
`;
export const BackgroundDiv = styled.div`
  position: absolute;
  width: ${props => props.w};
  height: ${props => props.h};
  top: ${props => props.top};
  left: ${props => props.left};
  right: ${props => props.right};
  bottom: ${props => props.bottom};
  z-index: ${props => props.z};
  img,
  .gatsby-image-wrapper {
    width: 100%;
  }
`;

export const BorderedImage = styled.div`
  position: relative;
  .top-border {
    position: absolute !important;
    top: -70px;
    top: ${props => props.top};
    left: 0;
    width: 100%;
    z-index: -1;
    height: 100px;
  }
  .bottom-border {
    position: absolute !important;
    bottom: -30px;
    bottom: ${({ bottom }) => (bottom ? bottom : 0)};
    top: ${props => props.topBottomBorder};
    left: 0;
    width: 100%;
    z-index: -1;
    height: 60px;
  }
  @media screen and (max-width: ${props => (props.tablet ? '950px' : '767px')}) {
    .top-border {
      top: -20px;
      top: ${props => props.topMobile};
      height: 40px;
    }
    .bottom-border {
      top: ${props => props.topBottomBorderMobile};
      bottom: -15px;
      bottom: ${props => props.bottomMobile};
      height: 30px;
    }
  }
`;

export const NarrowContainer = styled.div`
  max-width: 850px;
  margin: 0 auto;
`;
