import styled from 'styled-components';
import { BodyTextAlt } from '~/components/.base/headings';
import { breakpoint } from '~/constants/Breakpoint';
import { MegaMenuWrapper } from '~/components/Navigation/MegaMenu';

const shippingBarHeight = '40px';

export const ComponentWrapper = styled.nav`
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 20;
  transition: all 0.2s ease-in-out;
  .shipping-bar {
    position: relative;
    transition: all 0.05s ease-in-out;
    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    li {
      display: flex;
      align-items: center;
      &:not(:first-child):before {
        content: '|';
        display: inline-block;
        margin: 0 5px;
        color: inherit;
      }
      p:only-child {
        margin-bottom: 0;
      }
    }
    .shipping-seperator {
      display: inline-block;
      margin: 0 5px;
    }
  }
  &:not(.white-header) {
    background: #fff;
  }
  .burger {
    border: none;
    background: none;
  }
  .img-container {
    &.logo {
      display: inline-block;
    }
  }
  ul {
    list-style: none;
    padding: 0;
  }
  .sublink {
    display: block;
  }
  .altFocus {
    &:focus {
      &:not(:disabled) {
        box-shadow: 0 0 0 2px rgba(212, 33, 44, 0.75), 0 0 0 3px rgba(255, 255, 255, 0.6);
      }
    }
  }
  .flex-grow {
    &--right {
      button {
        position: relative;
        svg {
          margin: 0;
        }
      }
    }
  }

  .cart-count {
    position: absolute;
    top: -6px;
    right: -6px;
    width: 18px;
    height: 18px;
    font-size: 12px;
    background-color: #d4212c;
    color: #fff;
    border-radius: 50%;
  }
  @media ${breakpoint.maxXl} {
    background: #fff;
    box-shadow: 0 0 60px 0 rgba(46, 50, 51, 0.3);
    .ecomm-navigation__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &.top {
      box-shadow: none;
      &.hasHero:not(.menu-open) {
        background: transparent;
      }
      &.white-header {
        background-color: transparent;
        .ecomm-navigation__top {
          color: #fff;
        }
        .cart {
          svg path {
            fill: currentColor;
          }
        }
        svg path {
          fill: #fff;
        }
      }
    }
    &.menu-open {
      box-shadow: none;
      background: #fff;
    }
    .left-side {
      display: flex;
      align-items: center;
    }

    &.has-atc {
      box-shadow: none;
    }
    &.shipping-active {
      /* transform: translateY(90px); */
    }
    .shipping-bar {
      width: calc(100% - 32px);
      margin: 0 16px;
      padding: 11px 19px 16px 20px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      background-color: #edf2f2;
      flex-direction: column;
      .shipping-close {
        position: absolute;
        top: 0;
        right: 0;
        color: #d4212c;
        padding: 11px 19px;
        font-size: 24px;
        line-height: 18px;
        margin: 0;
      }
    }
    .right-side {
      button {
        margin-left: 1.5rem;
        svg {
          margin-right: 0;
        }
      }
    }
    .ecomm-header-link {
      position: relative;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 1px;
      transition: color 0.2s linear;
      &[aria-expanded='true'] {
        > .sublinks,
        ~ ${MegaMenuWrapper} {
          display: block;
        }
      }
      .toggle-button {
        position: relative;
        right: 0.5rem;
        border-radius: 0.25rem;
        svg g {
          fill: #5c6566;
        }
        &:focus {
          box-shadow: 0 0 0 1px rgba(212, 33, 44, 0.75), 0 0 0 3px rgba(255, 255, 255, 0.75);
        }
      }
      span {
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 1px;
      }
      &.last a {
        border-right: none;
      }
      .ecomm-header-link-inner {
        > a {
          color: #5c6566;
          margin-left: 1rem;
          padding: 1rem 1rem 1rem 0;
          display: inline-flex;
          align-items: center;
          border-bottom: 1px solid transparent;
        }
      }
      .ecomm-header-link-inner:hover {
        > a {
          border-color: #d4212c;
        }
      }
      .ecomm-header-link-inner > a:hover,
      .ecomm-header-link-inner > a:focus {
        border-color: #d4212c;
      }
      .ecomm-header-link-inner .toggle-button:focus {
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4), 0 0 0 3px rgba(212, 33, 44, 0.75);
      }
      .sublinks {
        display: none;
        position: absolute;
        top: 100%;
        border-radius: 30px;
        background-color: #ffffff;
        width: fit-content;
        min-width: 225px;
        left: calc((100% - 225px) / 2);
        -webkit-box-shadow: 1px 9px 15px 3px rgba(0, 0, 0, 0.25);
        box-shadow: 1px 9px 15px 3px rgba(0, 0, 0, 0.25);
        .sublink {
          color: #5c6566;
          padding: 15px 20px;
          border-bottom: 1px solid #e2e2e4;
          transition: 0.2s all linear;
          text-align: center;
          font-size: 12px;
          &:hover {
            color: #fff;
            background-color: #d4212c;
          }
          span {
            padding: 0px;
          }
          &.first {
            padding-top: 25px;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
          }
          &.last {
            padding-bottom: 25px;
            border-bottom: none;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
          }
        }
      }
    }
    .ecommerceMenu__accountLinks {
      a {
        &:hover,
        &:focus {
          opacity: 0.9;
        }
      }
    }
    .right-links {
      justify-content: flex-end;
    }
    .logo {
      height: 50px;
      width: 116px;
    }

    &.hasHero {
      &.top {
        .ecomm-navigation__top {
          ${BodyTextAlt} {
            color: #fff;
          }

          .ecommerceMenu__accountLinks {
            a {
              svg path {
                fill: #d4212c;
              }
              &:hover,
              &:focus {
                ${BodyTextAlt} {
                  color: inherit;
                }
              }
            }
          }
        }
      }
    }
    button {
      margin-right: 1rem;
    }
    .img-container {
      &.logo {
        height: 40px;
        width: 93px;
      }
      &.burger {
        display: block;
        padding: 18px 16px;
        outline: none;
        svg {
          width: 24px;
        }
      }
    }
  }

  @media ${breakpoint.xxl} {
    background: transparent;
    color: #5c6566;
    font-family: Montserrat, sans-serif;
    border-bottom: 1px solid #e2e2e4;
    transform: translateY(-100%);
    ${BodyTextAlt} {
      font-size: 10px;
    }
    .img-container {
      &.logo {
        height: 45px;
        width: 100px;
      }
    }
    .shipping-bar {
      width: calc(100% - 80px);
      height: ${shippingBarHeight};
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      margin: 0 40px;
      background-color: #edf2f2;
      align-items: center;
      .header {
        position: absolute;
        top: 12px;
        left: 20px;
      }
      .shipping-close {
        position: absolute;
        top: 11px;
        right: 20px;
        font-size: 24px;
        line-height: 18px;
        cursor: pointer;
      }
      p {
        margin: 0;
      }
    }
    .ecomm-navigation__top {
      padding: 1rem 40px 0;
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      width: 100%;
      a {
        display: inline-flex;
      }
      .flex-grow {
        flex-grow: 1;
        &--right {
          a {
            margin-right: 2rem;
            svg {
              margin-left: 0.25rem;
            }
            p {
              margin: 0;
            }
          }
        }
      }
    }
    .ecomm-header-link {
      position: relative;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 1px;
      transition: color 0.2s linear;
      &[aria-expanded='true'] {
        > .sublinks,
        ~ ${MegaMenuWrapper} {
          display: block;
        }
      }
      .toggle-button {
        position: relative;
        right: 0.5rem;
        border-radius: 0.25rem;
        svg g {
          fill: #5c6566;
        }
        &:focus {
          box-shadow: 0 0 0 1px rgba(212, 33, 44, 0.75), 0 0 0 3px rgba(255, 255, 255, 0.75);
        }
      }
      span {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
      }
      &.last a {
        border-right: none;
      }
      .ecomm-header-link-inner {
        > a {
          color: #5c6566;
          margin-left: 1rem;
          padding: 1rem 1rem 1rem 0;
          display: inline-flex;
          align-items: center;
          border-bottom: 1px solid transparent;
        }
      }
      .ecomm-header-link-inner:hover {
        > a {
          border-color: #d4212c;
        }
      }
      .ecomm-header-link-inner > a:hover,
      .ecomm-header-link-inner > a:focus {
        border-color: #d4212c;
      }
      .ecomm-header-link-inner .toggle-button:focus {
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4), 0 0 0 3px rgba(212, 33, 44, 0.75);
      }
      .sublinks {
        display: none;
        position: absolute;
        top: 100%;
        border-radius: 30px;
        background-color: #ffffff;
        width: fit-content;
        min-width: 225px;
        left: calc((100% - 225px) / 2);
        -webkit-box-shadow: 1px 9px 15px 3px rgba(0, 0, 0, 0.25);
        box-shadow: 1px 9px 15px 3px rgba(0, 0, 0, 0.25);
        .sublink {
          color: #5c6566;
          padding: 15px 20px;
          border-bottom: 1px solid #e2e2e4;
          transition: 0.2s all linear;
          text-align: center;
          font-size: 12px;
          &:hover {
            color: #fff;
            background-color: #d4212c;
          }
          span {
            padding: 0px;
          }
          &.first {
            padding-top: 25px;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
          }
          &.last {
            padding-bottom: 25px;
            border-bottom: none;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
          }
        }
      }
    }
    .ecommerceMenu__accountLinks {
      a {
        &:hover,
        &:focus {
          opacity: 0.9;
        }
      }
    }
    .right-links {
      justify-content: flex-end;
    }
    .logo {
      height: 50px;
      width: 116px;
    }

    &.hasHero {
      background: transparent;
      /* transition: all 0.2s ease-in-out; */
      &.top {
        color: #fff;
        .ecomm-header-link-inner > a {
          color: #fff;
        }
        .ecomm-navigation__top {
          ${BodyTextAlt} {
            color: #fff;
          }

          .ecommerceMenu__accountLinks {
            a {
              &:hover,
              &:focus {
                ${BodyTextAlt} {
                  color: inherit;
                }
              }
            }
          }
        }
      }
      &:not(.sticky) {
        .stacked-logo {
          path {
            fill: #ffffff; // Overwrite the change of color
          }
        }
      }
    }
    &.white-header {
      background: transparent;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      /* color: #ddd; */
      // margin-bottom: -133px;
      .shipping-close {
        color: #d4212c;
      }
      &.shipping-active {
        // margin-bottom: -173px;
        &:not(.sticky)&.top {
        }
      }
      .shipping-bar {
        color: #5c6566;
      }
      .ecomm-header-link {
        font-size: 12px;
        svg {
          g {
            fill: #ddd;
          }
        }
      }
    }
    &.top {
      transform: translateY(0);
      &.shipping-active {
        /* transform: translateY(${shippingBarHeight}); */
      }
    }
    &.shrinked {
      background: #fff;
      transition: all 0.2s ease-in-out;
      #announcementBar {
        height: 0;
        visibility: hidden;
      }
      > div {
        height: 100%;
      }
      .ecomm-navigation__top {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding-top: 0;
      }
      button {
        position: relative;
        svg {
          stroke: none;
        }
      }
      &.hidden {
        top: -92px;
      }
      &.has-atc {
        box-shadow: none;
      }
      .left-side {
        height: 100%;
        padding-left: 20px;
        .sticky-links {
          height: 100%;
        }
      }
      .right-side {
        height: 100%;
        a {
          display: flex;
          margin-right: 1rem;
          svg {
            margin-left: 0.25rem;
          }
          p {
            margin: 0;
          }
        }
        button svg {
          margin: 0;
        }
      }
      .ecomm-header-link-inner {
        border-right: 1px solid #c2cbcc;
        span {
          padding: 0 0.4rem;
        }
        > a {
          border-bottom: none;
          margin-left: 0;
          padding: 0;
          &:hover {
            color: #d4212c;
          }
        }
        .toggle-button {
          right: 0.5rem;
        }
        &:hover {
          > a {
            color: #d4212c;
          }
          .toggle-button svg {
            stroke: #d4212c;
          }
        }
      }
      .ecomm-header-link {
        font-size: 10px;
        padding: 22.5px 0;
        &.last .ecomm-header-link-inner {
          border-right: none;
        }
        svg {
          width: 10px;
          height: 10px;
        }
        .toggle-button {
          svg g {
            fill: #5c6566;
          }
        }
        > a:hover,
        > a:focus {
          ~ .sublinks,
          ~ ${MegaMenuWrapper} {
            margin-top: 0px;
          }
          svg g {
            fill: #d4212c;
          }
        }
        .ecommerceMenu__accountLinks {
          a {
            &:hover,
            &:focus {
              color: #d4212c;
              svg g {
                fill: #d4212c;
              }
            }
          }
        }
      }
      .logo {
        height: 22px;
        width: 190px;
        margin: 0 1rem;
      }
      /* @media screen and (max-width: 1150px) {
        .ecomm-header-link {
          display: none;
        }
      } */
    }
    &.sticky {
      transform: translateY(0);
      box-shadow: 0 0 60px 0 rgba(46, 50, 51, 0.2);
    }
  }
  @media ${breakpoint.xxxl} {
    ${BodyTextAlt} {
      font-size: 12px;
    }
    .shipping-bar {
    }
    .ecomm-navigation__top {
    }
    .ecomm-header-link-inner {
      > a {
        padding: 1rem 1.4rem 1rem 0;
      }
      span {
        font-size: 12px;
      }
    }
    &.shrinked {
      .left-side {
        padding-left: 40px;
      }
      .right-side {
        padding-right: 40px;
        a {
          margin-right: 2rem;
        }
      }
      .ecomm-header-link-inner {
        span {
          padding: 0 0.6rem;
        }
      }
    }
  }
  @media ${breakpoint.onlyXxl} {
    &.shrinked {
      .ecomm-header-link-inner {
        > a {
          padding: 0rem 0.2rem 0rem 0;
        }
        span {
          font-size: 10px;
        }
      }
      .right-side {
        a {
          ${BodyTextAlt} {
            display: none;
          }
        }
      }
    }
  }
`;
