import styled from "styled-components";
import { breakpoint } from "../../constants/Breakpoint";
import { WhiteButton } from "../.base/buttons";

export const MegaMenuWrapper = styled.div`
  background-color: #fff;
  position: absolute;
  width: 100%;
  left: 0;
  color: #5c6566;
  display: none;
  -webkit-box-shadow: 1px 9px 15px 3px rgba(0, 0, 0, 0.25);
  box-shadow: 1px 9px 15px 3px rgba(0, 0, 0, 0.25);
  margin-top: 1px;
  .tout-info {
    ${WhiteButton} {
      min-width: 40px;
      padding: 0;
    }
  }
  a {
    display: block;
  }
  // quick image styles
  .gatsby-image-wrapper {
    border-radius: 30px;
    object-fit: contain;
  }
  .big-tout .gatsby-image-wrapper {
    max-height: 236px;
  }

  .little-touts .gatsby-image-wrapper {
    max-height: 108px;

    img {
      max-height: 108px;
    }
  }
  .mega-menu-links-cont {
    padding: 30px 40px 0px 40px;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    .mega-menu-link-text {
      transition: 0.2s all linear;
      color: #5c6566;
      margin-bottom: 11px;
    }
    .mega-menu-link:hover {
      .mega-menu-link-text {
        color: #d4212c;
      }
    }
  }
  .tout-cont {
    padding: 20px 40px 30px;
    .big-tout {
      flex: 4;
      > div {
        height: 100%;
      }
      .gatsby-image-wrapper {
        flex: 0 0 60%;
        max-width: 60%;
      }
      .tout-info {
        padding: 0 30px 0 10px;
      }
    }
    .little-touts {
      flex: 3;

      a:nth-child(1) {
        margin-bottom: 20px;
      }
      .gatsby-image-wrapper,
      .tout-info {
        flex: 0 0 50%;
        max-width: 50%;
      }
      .tout-info {
        padding: 0 0px 0 10px;
        &.p-b {
          padding-bottom: 10px;
        }
        &.p-t {
          padding-top: 10px;
        }
      }
    }
    p,
    h2 {
      transition: 0.2s all linear;
    }
    p {
      color: #5c6566;
    }
    .tout:hover {
      p,
      h2 {
        color: #d4212c;
      }
      button {
        background-color: #d4212c;
        svg {
          stroke: #fff;
          fill: #fff;
        }
      }
    }
  }
  &:hover {
    display: block;
  }
  @media screen and (min-width: 951px) and (max-width: 1100px) {
    .tout-cont {
      padding-left: 0;
      h2 {
        font-size: 18px;
      }
    }
  }

  @media ${breakpoint.xl} {
    .mega-menu-links-cont {
      padding-right: 7.5%;
    }
  }
`;
