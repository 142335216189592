import { useMediaQuery } from 'react-responsive';
import { size } from '../../constants/Breakpoint';

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: size.xl });
  return isDesktop ? children : null;
};
export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: size.md, maxWidth: size.xl - 1 });
  return isTablet ? children : null;
};
export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: size.lg - 1 });
  return isMobile ? children : null;
};
export const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: size.lg });
  return isNotMobile ? children : null;
};

export const Xxl = ({ children }) => {
  const show = useMediaQuery({ minWidth: size.xxl });
  return show ? children : null;
};

export const MaxXl = ({ children }) => {
  const show = useMediaQuery({ maxWidth: size.xxl - 1 });
  return show ? children : null;
};

export const OnlyXl = ({ children }) => {
  const show = useMediaQuery({ minWidth: size.xl, maxWidth: size.xxl - 1 });
  return show ? children : null;
};

// export const handleIsMobile = () => useMediaQuery({ query: `(max-width: ${size.lg - 1}px)`})
// export const handleIsTabletOrMobile = () => useMediaQuery({ query: `(max-width: ${size.xl - 1}px)`})
