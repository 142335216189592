import styled from 'styled-components';

export const IconWrapper = styled.span`
    display: inline-flex;
`;

export const IconLink = styled.a`
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #5c6566;
    text-transform: uppercase;
    font-family: Montserrat, sans-serif;
    ${IconWrapper} {
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid #c4cbcc;
        margin-left: 10px;
    }
    .icon {
        color: #d4212c;
        width: 16px;
        height: auto;
    }
    &:focus {
        &:not(:disabled) {
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4), 0 0 0 3px rgba(212, 33, 44, 0.75);
        }
    }
`;

export const UnderscoreLink = styled.a`
    color: #d4212c;
    text-decoration: underline;
    &:focus {
        outline: none;
        &:not(:disabled) {
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4), 0 0 0 3px rgba(212, 33, 44, 0.75);
        }
    }
`;
