import React from 'react';
import { Fade, Tooltip as TooltipComponent } from '@material-ui/core';
import PropTypes from 'prop-types';

import { BodyText } from './headings';

const Tooltip = ({ title, placement, children }) => {
    return (
        <TooltipComponent
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            placement={placement}
            title={title}
            arrow
            enterTouchDelay={50}
            interactive
        >
            <button className="tooltip-btn">{children}</button>
        </TooltipComponent>
    );
};

Tooltip.defaultProps = {
    placement: 'top',
};

Tooltip.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    placement: PropTypes.string,
};

export default Tooltip;
