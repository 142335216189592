import React, { useContext, useState, useEffect } from 'react';
import StoreContext from '~/layouts/StoreContext';
import { getShopifyImageTransformedSrc } from 'helpers';
import { getProductVariantInventory } from '~/helpers/requests/association-requests';
import { BodyText, BodyTextAlt } from '~/components/.base/headings';
import { FlexBox, Grid, Row, Col } from '~/components/.base/containers';
import { WhiteButton } from '~/components/.base/buttons';
import { UnderscoreLink } from '~/components/.base/links';
import PlusIcon from '~/images/icons/plus.svg';
import MinusIcon from '~/images/icons/minus.svg';
import SubscriptionIcon from '~/images/icons/pdp/subscription.svg';
import CloseIcon from '~/images/icons/pdp/close.svg';
import { css } from 'styled-components';
import TagIcon from '~/images/icons/tag.svg';

const LineItem = ({ lineItem, index }) => {
  const {
    removeLineItem,
    removeBaseLineItem,
    updateLineItem,
    addVariantToCart,
    closeCart,
    store: {
      client,
      checkout,
      trueLinePrices,
      lobsterTitle,
      lobsterSubscriptionTitle,
      linkDiscountCode,
    },
  } = useContext(StoreContext);

  const [quantityAvailable, setQuantityAvailable] = useState(0);
  const [isUpsellItem, setIsUpsellItem] = useState(false);
  const [subscriptionInterval, setSubscriptionInterval] = useState('30');

  // Check to render subscription option
  useEffect(() => {
    lineItem.customAttributes.forEach(item => {
      if (item.key === 'subscription_interval') {
        setSubscriptionInterval(item.value);
      }
    });
  }, [lineItem]);

  // ***** Get available quantity on shopify ********
  useEffect(() => {
    (async () => {
      try {
        const available = await getProductVariantInventory(lineItem.variant.id);
        setQuantityAvailable(available.data.data.node.quantityAvailable);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [lineItem]);

  // ******** UP SELL CHECK *********
  useEffect(() => {
    if (lineItem.customAttributes) {
      lineItem.customAttributes.forEach(item =>
        item.key === 'upsell_product' ? setIsUpsellItem(true) : null
      );
    }
  }, [lineItem]);

  const variantImage = lineItem.variant.image ? (
    <img
      src={getShopifyImageTransformedSrc(lineItem.variant.image.src, '_60x')}
      alt=""
      height="58px"
    />
  ) : null;

  const handleRemove = () => {
    removeLineItem(client, checkout.id, lineItem.id);
  };

  const handleSubtract = () => {
    if (lineItem.quantity === 1) {
      handleRemove();
    } else {
      updateLineItem(client, checkout.id, lineItem.id, lineItem.quantity - 1);
    }
  };

  const handleAdd = () => {
    if (lineItem.quantity + 1 === quantityAvailable) {
      return;
    }
    if (isUpsellItem) {
      const baseProductId = lineItem.customAttributes.find(
        attr => attr.key === 'base_product_for_upsell_var_id'
      )?.value;
      const baseProductQuantity = checkout.lineItems.find(
        item => item.variant.id === baseProductId
      )?.quantity;

      if (lineItem.quantity >= baseProductQuantity) return;
    }
    updateLineItem(client, checkout.id, lineItem.id, lineItem.quantity + 1);
  };

  const changeInterval = async interval => {
    await removeLineItem(client, checkout.id, lineItem.id);
    if (lineItem.customAttributes) {
      const updatedAttributes = lineItem.customAttributes.map(atr => {
        if (atr.key === 'subscription_interval') {
          return {
            key: 'subscription_interval',
            value: interval,
          };
        }
        return {
          key: atr.key,
          value: atr.value,
        };
      });
      addVariantToCart(lineItem.variant.id, lineItem.quantity, updatedAttributes);
    }
  };

  const handleRegularProductSubstitute = async variantId => {
    await removeLineItem(client, checkout.id, lineItem.id);
    let attrs = [];
    for (let attr of lineItem.customAttributes) {
      if (attr.key !== 'regular_variant_id' && attr.key !== 'subscription_interval') {
        attrs.push({ key: attr.key, value: attr.value });
      }
    }
    // NOTE: remove this until subscriptions are enabled again
    // attrs.push({
    //   key: 'subscription_variant_id',
    //   value: lineItem.variant.id,
    // });
    addVariantToCart(variantId, lineItem.quantity, attrs);
  };

  return trueLinePrices && trueLinePrices[index] ? (
    <Grid justify="space-between" align="center" className="line-item">
      <Row className="upper-half">
        <Col className="left-half" size={5}>
          <FlexBox>
            <div className="line-item-img">{variantImage}</div>
            <div>
              <BodyText className="line-item-title">
                {lineItem.title}
                {lineItem.variant.title !== 'Default Title' ? ` - ${lineItem.variant.title}` : ''}
              </BodyText>
              <UnderscoreLink as="button" onClick={handleRemove}>
                Remove
              </UnderscoreLink>
            </div>
          </FlexBox>
        </Col>
        <Col className="right-half" size={1}>
          {trueLinePrices[index].codeDiscountAmount ? (
            <div className="savings">
              <FlexBox justify="flex-end">
                <BodyTextAlt md="0 0 10px">
                  <span class="sr-only">Original price:</span>
                  <strike>${Number(trueLinePrices[index].basePrice).toFixed(2)}</strike>
                </BodyTextAlt>{' '}
                <BodyTextAlt md="0 0 10px">
                  <span class="sr-only">Current price:</span>$
                  {Number(trueLinePrices[index].price).toFixed(2)}
                </BodyTextAlt>
              </FlexBox>
              {Math.round(
                Number(trueLinePrices[index].basePrice) -
                  Number(trueLinePrices[index].price) -
                  Number(trueLinePrices[index].codeDiscountAmount)
              ) > 0 ? (
                <BodyTextAlt color="#d4212c" md="0 0 10px">
                  Saving $
                  {(
                    Number(trueLinePrices[index].basePrice) -
                    Number(trueLinePrices[index].price) -
                    Number(trueLinePrices[index].codeDiscountAmount)
                  ).toFixed(2)}
                </BodyTextAlt>
              ) : null}
              <FlexBox align="center">
                <TagIcon style={{ width: '17px', marginRight: '5px', fill: '#555' }} />
                <BodyTextAlt color="#d4212c" md="0">
                  {trueLinePrices[index].code}:{` `}$
                  {Number(trueLinePrices[index].codeDiscountAmount).toFixed(2)}
                </BodyTextAlt>
              </FlexBox>
            </div>
          ) : trueLinePrices[index].price !== Number(trueLinePrices[index].basePrice) ? (
            <div className="savings">
              <FlexBox>
                <BodyTextAlt>
                  <span class="sr-only">Original price:</span>
                  <strike>${Number(trueLinePrices[index].basePrice).toFixed(2)}</strike>
                </BodyTextAlt>{' '}
                <BodyTextAlt>
                  <span class="sr-only">Current price:</span>$
                  {Number(trueLinePrices[index].price).toFixed(2)}
                </BodyTextAlt>
              </FlexBox>
              <BodyTextAlt color="#d4212c">
                Saving $
                {(
                  Number(trueLinePrices[index].basePrice) - Number(trueLinePrices[index].price)
                ).toFixed(2)}
              </BodyTextAlt>
            </div>
          ) : (
            <BodyTextAlt>${trueLinePrices[index].price.toFixed(2)}</BodyTextAlt>
          )}
        </Col>
      </Row>
      <Row className="lower-half">
        {!lineItem.customAttributes.find(prop => prop.key === 'recipient_name') ? (
          <Col size={2} maxWidth="40%">
            <div className="quantity">
              <button
                onClick={() => {
                  if (
                    (lobsterTitle === lineItem.title && lineItem.quantity === 2) ||
                    (lobsterSubscriptionTitle === lineItem.title && lineItem.quantity === 2)
                  ) {
                    return;
                  } else {
                    handleSubtract();
                  }
                }}
              >
                <MinusIcon />
              </button>
              {lineItem.quantity < 10 ? `0${lineItem.quantity}` : lineItem.quantity}
              <button onClick={handleAdd}>
                <PlusIcon style={{ marginBottom: '1px' }} />
              </button>
            </div>
          </Col>
        ) : (
          <div style={{ height: '20px' }} />
        )}
        <Col size={3} maxWidth="60%">
          {lineItem.customAttributes.find(prop => prop.key === 'regular_variant_id') ? (
            <FlexBox className="subscribe-save" align="center" justify="flex-end">
              <BodyTextAlt className="text" md="0 0 0 0">
                Ships Every
              </BodyTextAlt>
              <div className="btn-container">
                <select
                  className="subscription-int-dropdown"
                  value={subscriptionInterval}
                  onChange={e => changeInterval(e.target.value)}
                  onBlur={e => changeInterval(e.target.value)}
                >
                  <option value="30">30 DAYS</option>
                  <option value="60">60 DAYS</option>
                  <option value="90">90 DAYS</option>
                </select>
                <WhiteButton
                  className="close"
                  onClick={() =>
                    handleRegularProductSubstitute(
                      lineItem.customAttributes.find(prop => prop.key === 'regular_variant_id')
                        .value
                    )
                  }
                >
                  <CloseIcon />
                </WhiteButton>
              </div>
            </FlexBox>
          ) : null}
        </Col>
      </Row>
    </Grid>
  ) : null;
};

export default LineItem;
