export const getMetafield = (metafields, key) => {
  const mfValue = metafields?.find(edge => edge.key === key);
  return mfValue ? mfValue.value : '';
};
export const getMetafieldNode = (metafields, key) => {
  const mfValue = metafields.find(edge => edge.node.key === key);
  return mfValue ? mfValue.node.value : '';
};

export const getImage = (images, key) => {
  return images && images.find(item => item.node.name === key)
    ? images.find(item => item.node.name === key).node.childImageSharp.fluid
    : null;
};

export const getImageFixed = (images, key) => {
  return images && images.find(item => item.node.name === key)
    ? images.find(item => item.node.name === key).node.childImageSharp.fixed
    : null;
};

export const getImageWidth = (images, key) => {
  return images && images.find(item => item.node.name === key)
    ? images.find(item => item.node.name === key).node.childImageSharp.original.width
    : null;
};

export const getShopifyImageTransformedSrc = (originalSrc, transform) => {
  let extensionIndex = originalSrc.lastIndexOf('?v=') - 4;
  let fileName = originalSrc.substring(0, extensionIndex);
  let extension = originalSrc.substring(extensionIndex, extensionIndex + originalSrc.length);
  return fileName + transform + extension;
};

export const handleize = function (str) {
  str = str.toLowerCase();

  var toReplace = ['"', "'", '\\', '(', ')', '[', ']'];

  // For the old browsers
  for (var i = 0; i < toReplace.length; ++i) {
    str = str.replace(toReplace[i], '');
  }

  str = str.replace(/\W+/g, '-');

  if (str.charAt(str.length - 1) === '-') {
    str = str.replace(/-+\z/, '');
  }

  if (str.charAt(0) === '-') {
    str = str.replace(/\A-+/, '');
  }

  return str;
};

export const handleizeLineItem = lineItem => {
  // Subscription Product
  if (lineItem?.customAttributes?.find(prop => prop.key === 'regular_variant_id')) {
    if (lineItem.title.endsWith(' Subscription')) {
      return handleize(lineItem.title.slice(0, -13));
    }
  }

  // Regular Product
  return handleize(lineItem.title);
};

export function removeDuplicates(originalArray, prop) {
  var newArray = [];
  var lookupObject = {};

  for (var i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
}

export const formatDate = (date, step) => {
  var d = new Date(date);
  d.setDate(d.getDate() + step);
  return (
    (d.getMonth() > 8 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)) +
    '/' +
    (d.getDate() > 9 ? d.getDate() : '0' + d.getDate()) +
    '/' +
    d.getFullYear()
  );
};

export const formatMoney = number => {
  return '$' + (Number(number) / 100).toFixed(2);
};

export const appendFormData = values => {
  var formData = new FormData();
  for (let pair of Object.entries(values)) {
    formData.append(pair[0], pair[1]);
  }
  return formData;
};

export const reformatArr = arr => {
  const grid = [];
  var hitMaxDepth = false;
  var maxDepth = 1;
  for (let i = 0; !hitMaxDepth; i++) {
    let row = [];
    for (let col of arr) {
      if (col && col[i]) {
        row.push(col[i]);
      } else {
        row.push(null);
      }
      if (col && col.length > maxDepth) {
        maxDepth = col.length;
      }
    }
    grid.push(row);
    if (i + 1 === maxDepth) hitMaxDepth = true;
  }
  return grid;
};

export const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const stateLists = {
  US: {
    AK: 'Alaska',
    AL: 'Alabama',
    AR: 'Arkansas',
    AZ: 'Arizona',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DC: 'District of Columbia',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    IA: 'Iowa',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    MA: 'Massachusetts',
    MD: 'Maryland',
    ME: 'Maine',
    MI: 'Michigan',
    MN: 'Minnesota',
    MO: 'Missouri',
    MS: 'Mississippi',
    MT: 'Montana',
    NC: 'North Carolina',
    ND: 'North Dakota',
    NE: 'Nebraska',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NV: 'Nevada',
    NY: 'New York',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VA: 'Virginia',
    VT: 'Vermont',
    WA: 'Washington',
    WI: 'Wisconsin',
    WV: 'West Virginia',
    WY: 'Wyoming',
  },
  CA: {
    AB: 'Alberta',
    BC: 'British Columbia',
    MB: 'Manitoba',
    NB: 'New Brunswick',
    NL: 'Newfoundland',
    NS: 'Nova Scotia',
    NT: 'Northwest Territories',
    NU: 'Nunavut',
    ON: 'Ontario',
    PE: 'Prince Edward Island',
    QC: 'Quebec',
    SK: 'Saskatchewan',
    YT: 'Yukon',
  },
};

export const getHashCode = string => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) hash = (Math.imul(31, hash) + string.charCodeAt(i)) | 0;
  return hash;
};
