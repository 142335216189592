// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-account-activate-js": () => import("./../../../src/pages/account/activate.js" /* webpackChunkName: "component---src-pages-account-activate-js" */),
  "component---src-pages-account-addresses-address-jsx": () => import("./../../../src/pages/account/addresses/Address.jsx" /* webpackChunkName: "component---src-pages-account-addresses-address-jsx" */),
  "component---src-pages-account-addresses-edit-address-jsx": () => import("./../../../src/pages/account/addresses/EditAddress.jsx" /* webpackChunkName: "component---src-pages-account-addresses-edit-address-jsx" */),
  "component---src-pages-account-addresses-index-js": () => import("./../../../src/pages/account/addresses/index.js" /* webpackChunkName: "component---src-pages-account-addresses-index-js" */),
  "component---src-pages-account-dashboard-index-js": () => import("./../../../src/pages/account/dashboard/index.js" /* webpackChunkName: "component---src-pages-account-dashboard-index-js" */),
  "component---src-pages-account-login-js": () => import("./../../../src/pages/account/login.js" /* webpackChunkName: "component---src-pages-account-login-js" */),
  "component---src-pages-account-orders-index-js": () => import("./../../../src/pages/account/orders/index.js" /* webpackChunkName: "component---src-pages-account-orders-index-js" */),
  "component---src-pages-account-register-js": () => import("./../../../src/pages/account/register.js" /* webpackChunkName: "component---src-pages-account-register-js" */),
  "component---src-pages-account-reset-js": () => import("./../../../src/pages/account/reset.js" /* webpackChunkName: "component---src-pages-account-reset-js" */),
  "component---src-pages-account-subscriptions-index-js": () => import("./../../../src/pages/account/subscriptions/index.js" /* webpackChunkName: "component---src-pages-account-subscriptions-index-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-catering-js": () => import("./../../../src/pages/catering.js" /* webpackChunkName: "component---src-pages-catering-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-collection-list-js": () => import("./../../../src/pages/collection-list.js" /* webpackChunkName: "component---src-pages-collection-list-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-create-order-js": () => import("./../../../src/pages/create-order.js" /* webpackChunkName: "component---src-pages-create-order-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-franchise-js": () => import("./../../../src/pages/franchise.js" /* webpackChunkName: "component---src-pages-franchise-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locations-index-js": () => import("./../../../src/pages/locations/index.js" /* webpackChunkName: "component---src-pages-locations-index-js" */),
  "component---src-pages-locations-search-js": () => import("./../../../src/pages/locations/search.js" /* webpackChunkName: "component---src-pages-locations-search-js" */),
  "component---src-pages-mobile-app-js": () => import("./../../../src/pages/mobile-app.js" /* webpackChunkName: "component---src-pages-mobile-app-js" */),
  "component---src-pages-no-tickets-needed-js": () => import("./../../../src/pages/no-tickets-needed.js" /* webpackChunkName: "component---src-pages-no-tickets-needed-js" */),
  "component---src-pages-order-completed-js": () => import("./../../../src/pages/order-completed.js" /* webpackChunkName: "component---src-pages-order-completed-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-press-index-js": () => import("./../../../src/pages/press/index.js" /* webpackChunkName: "component---src-pages-press-index-js" */),
  "component---src-pages-press-kit-js": () => import("./../../../src/pages/press/kit.js" /* webpackChunkName: "component---src-pages-press-kit-js" */),
  "component---src-pages-press-news-js": () => import("./../../../src/pages/press/news.js" /* webpackChunkName: "component---src-pages-press-news-js" */),
  "component---src-pages-press-speaking-engagements-js": () => import("./../../../src/pages/press/speaking-engagements.js" /* webpackChunkName: "component---src-pages-press-speaking-engagements-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-recipes-js": () => import("./../../../src/pages/recipes.js" /* webpackChunkName: "component---src-pages-recipes-js" */),
  "component---src-pages-refund-and-shipping-policy-js": () => import("./../../../src/pages/refund-and-shipping-policy.js" /* webpackChunkName: "component---src-pages-refund-and-shipping-policy-js" */),
  "component---src-pages-safety-js": () => import("./../../../src/pages/safety.js" /* webpackChunkName: "component---src-pages-safety-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-website-terms-of-use-js": () => import("./../../../src/pages/website-terms-of-use.js" /* webpackChunkName: "component---src-pages-website-terms-of-use-js" */),
  "component---src-templates-collection-page-index-js": () => import("./../../../src/templates/CollectionPage/index.js" /* webpackChunkName: "component---src-templates-collection-page-index-js" */),
  "component---src-templates-location-page-index-js": () => import("./../../../src/templates/LocationPage/index.js" /* webpackChunkName: "component---src-templates-location-page-index-js" */),
  "component---src-templates-product-page-index-js": () => import("./../../../src/templates/ProductPage/index.js" /* webpackChunkName: "component---src-templates-product-page-index-js" */)
}

