import React from 'react';
import { FlexBox } from '~/components/.base/containers';
import './AnnouncementBar.scss';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

const AnnouncementBar = ({ linkUrl, bgColor = '1c75bc', content }) => {
  return (
    <FlexBox className="announcement-bar" justify="space-between" align="center">
      <div className="announcement-bar-wrapper" style={{ background: `#${bgColor}` }}>
        <a href={linkUrl} target="_blank">
          {renderRichText(content)}
        </a>
      </div>
    </FlexBox>
  );
};

export default AnnouncementBar;
